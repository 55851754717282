import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Breadcrumb, BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    standalone: true
})
export class BreadcrumbComponent {
  breadcrumbs$: Observable<Breadcrumb[]>;
  constructor(private readonly breadcrumbService: BreadcrumbService, private router: Router) {
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;
  }


  navigate(url: string){
    let [route, prop] = url.split('/').filter(i => i !== "");
    if(this.router.url === url) return
    if(prop){
      this.router.navigate(['/' + route, prop]);
    } else {
      this.router.navigate(['/' + route]);
    }
  }
}
