<div class="notification-message" *ngFor="let msg of info.openInfoMessages" [class]="classGenerator(msg.type)">
  <span>{{ msg.message }}</span> <span class="material-icons error-close" (click)="info.removeMessage(msg)">close</span>
</div>

@if (info.news.length > 0) {
  <div class="new-version">
    <strong>Ny versjon av DatalexWeb!</strong>
    <span>En ny versjon av DatalexWeb er tilgjengelig. Vennligst last siden på nytt for å oppdatere.</span>
    <button (click)="refresh()">Oppdater</button>
  </div>
}