import { Component, ComponentRef, OnInit } from '@angular/core';
import { DocumentUploadModalComponent } from 'src/app/components/UI/modal-hub/modals/document-upload-modal/document-upload-modal.component';
import { RandomId } from 'src/app/util/RandomId';
import { ICaseBE } from '@datalex-software-as/datalex-client';
import { IgxGridComponent, IgxColumnComponent } from '@infragistics/igniteui-angular';
import { NgIf } from '@angular/common';
import { DeviceService } from 'src/app/services/device.service';

interface IDocumentUploadGridItem {
  title: string,
  name: string,
  size: number,
  lastModified: Date,
  type: string,
  sizeInMB: string,
  id: string
}

@Component({
  selector: 'app-upload-document-grid',
  templateUrl: './upload-document-grid.component.html',
  styleUrls: ['./upload-document-grid.component.scss'],
  standalone: true,
  imports: [NgIf, IgxGridComponent, IgxColumnComponent]
})
export class UploadDocumentGridComponent implements OnInit {

  constructor(public deviceService: DeviceService) { }
  public case!: ICaseBE;
  public files!: FileList;
  public self!: ComponentRef<DocumentUploadModalComponent>;
  fileArray!: File[];
  gridContent!: IDocumentUploadGridItem[];
  selectedRows!: IDocumentUploadGridItem[];

  ngOnInit(): void {
    this.fileArray = Array.from(this.files);
    this.gridContent = this.createGridItems(this.fileArray);
  }

  handleRowSelection(event: any) {
    this.selectedRows = event.newSelection;
  }


  createGridItems(files: File[]): IDocumentUploadGridItem[] {
    let _return: IDocumentUploadGridItem[] = [];
    files.forEach(file => {
      const { lastModified, name, size, type } = file;
      _return.push({ lastModified: new Date(lastModified), name, size, type, title: file.name.split('.')[0], sizeInMB: this.getFileSize(file.size), id: RandomId() })
    })
    return _return;
  }

  getFileSize(bytes: number): string {
    let m = '';
    let size = 0;
    if (bytes < 1000) {
      return "1 KB"
    }
    else if (bytes < 1000000) {
      m = "KB"
      size = bytes / 1000;
    }
    else if (bytes < 1000000000) {
      m = "MB";
      size = bytes / 1000000;
    }
    else {
      m = "GB";
      size = bytes / 1000000000;
    }

    return `${Math.round(size)} ${m}`

  }

  onGridSave() {
  }
  onRowRemove() {
    const results = this.gridContent.filter(({ id: id1 }) => !this.selectedRows.some(({ id: id2 }) => id2 === id1));
    this.gridContent = results;
  }
}
