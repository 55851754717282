<igx-select [overlaySettings]="{ outlet: element }">
    <label igxLabel>Pick your fruit</label>
    <igx-select-item *ngFor="let fruit of fruits" [value]="fruit.type" class="select__entry" [text]="fruit.type">
        {{fruit.type}}
    </igx-select-item>
    <ng-template igxSelectHeader>
        <div class="custom-select-header">

        </div>
    </ng-template>
</igx-select>