
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SystemCacheService } from 'src/app/services/system-cache.service';
// import {
//   DatalexClient,
//   IDocumentTypeBE,
// } from '@datalex-software-as/datalex-client';
import {
  DatalexClient,
  IDocumentTypeBE,
} from '@datalex-software-as/datalex-client';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridMethodsService } from 'src/app/services/grid-methods.service';
import { IgxOverlayService, IgxButtonDirective, IgxRippleDirective, IgxGridComponent, IgxColumnComponent, IgxFilterCellTemplateDirective } from '@infragistics/igniteui-angular';
import { DocumentTypeBE } from 'src/app/classes/DocumentTypeBE'
import { ButtonRowButtonComponent } from '../../UI/button-row-button/button-row-button.component';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-document-types',
  templateUrl: './document-types.component.html',
  styleUrls: ['./document-types.component.scss'],
  standalone: true,
  imports: [ButtonRowButtonComponent, FormsModule, ReactiveFormsModule, IgxButtonDirective, IgxRippleDirective, IgxGridComponent, IgxColumnComponent, IgxFilterCellTemplateDirective]
})
export class DocumentTypesComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private sys: SystemCacheService,
    private dlxClient: DatalexClient,
    public gms: GridMethodsService,
    public deviceService: DeviceService,
    @Inject(IgxOverlayService) private overlayService: IgxOverlayService
  ) {
    

  }

  types!: IDocumentTypeBE[];
  type!: IDocumentTypeBE;
  isSelected = true;

  listenType!: any;

  documentTypeForm = this.newForm();


  @ViewChild('warningDisplay') warning!: ElementRef<HTMLDivElement>;
  warningText: string = '';

  newForm() {
    return new FormGroup({
      Name: new FormControl(''),
      IsTemplateType: new FormControl(false),
      Id: new FormControl(''),
      IsNew: new FormControl(true),
      IsDeleted: new FormControl(false),
      DeleteAllowed: new FormControl(true),
      Order: new FormControl(0),
      Timestamp: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.sys.executeWhenReady(this.get);
  }

  ngAfterViewInit(): void { }

  ngOnDestroy(): void { }

  toggleActionPanel(actionPanel: HTMLDivElement) {
    this.documentTypeForm = this.newForm();
    this.isSelected = !this.isSelected;
    this.hide(this.warning.nativeElement);
    actionPanel.classList.contains('grap-hidden')
      ? this.show(actionPanel)
      : this.hide(actionPanel);

  }

  show(ap: HTMLDivElement) {
    ap.classList.remove('grap-hidden');
    ap.classList.add('grap-show');
  }

  hide(ap: HTMLDivElement) {
    ap.classList.remove('grap-show');
    ap.classList.add('grap-hidden');
  }



  get() {
    this.dlxClient.GetDocumentTypes().subscribe({
      next: (res) => {
        this.types = res;

        const dType = new DocumentTypeBE(res[0]);
        
        dType.changed.subscribe({
          next: this.onChange
        });
        dType.setName("New name");
        
      },
    });
  }

  onChange(e: IDocumentTypeBE) {
    
  }

  save(type: IDocumentTypeBE, ap: HTMLDivElement) {
    this.dlxClient.SaveDocumentType(type).subscribe({
      next: () => {

        this.hide(ap);
        this.documentTypeForm = this.newForm();
        this.get();
      },
    });
  }

  onSubmit(ap: HTMLDivElement) {
    const exists = this.types.find(
      (item) => item.Name === this.documentTypeForm.value.Name
    );
    if (exists) {
      this.warningText = 'Denne types finnes allerede!';
      this.show(this.warning.nativeElement);
      return;
    }

    if (this.documentTypeForm.value.IsNew) {
      this.dlxClient.NewDocumentType().subscribe({
        next: (res) => {
          //fylle inn data i type
          res.IsTemplateType = this.documentTypeForm.value.IsTemplateType!;
          res.Name = this.documentTypeForm.value.Name!;
          res.DeleteAllowed = true;
          this.save(res, ap);
        },
      });
    } else {
      this.save((this.documentTypeForm.value! as unknown as IDocumentTypeBE), ap);

    }



  }

  getData(data: any, ap: HTMLDivElement) {
    this.hide(this.warning.nativeElement);
    this.documentTypeForm.setValue(data.cell.row.data);
    this.show(ap);
    this.isSelected = false;
  }

  update() { }

  delete(ap: HTMLDivElement) {
    if (this.documentTypeForm.value.DeleteAllowed) {
      this.dlxClient
        .DeleteDocumentType(this.documentTypeForm.value.Id!)
        .subscribe({
          next: () => {
            this.documentTypeForm = this.newForm();
            this.hide(ap);
            this.get();
            this.isSelected = true;
          },
        });
    } else {
      this.warningText = 'Denne typen kan ikke slettes!';
      this.show(this.warning.nativeElement);
    }
  }

  destroy() {
    this.overlayService.hideAll()
  }
}
