import { Component, OnInit } from '@angular/core';
import { Activity } from 'src/app/classes/Activity/Actvity';
import { ActivityLogService } from './activity-log.service';
import { ActivityLogOverlayItemComponent } from './activity-log-overlay-item/activity-log-overlay-item.component';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'activity-log-overlay',
    templateUrl: './activity-log-overlay.component.html',
    styleUrls: ['./activity-log-overlay.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, ActivityLogOverlayItemComponent, NgClass]
})
export class ActivityLogOverlayComponent {

  constructor(public als: ActivityLogService) {

  }

  setBackGround(activity: Activity) {
    const { type } = activity;
    switch (type) {
      case 'warning':
        return '#ffd000'

      case 'failure':
        return '#ff5252'

      default:
        return '#06c706'
    }
  }

  getActivityClass(activity: Activity) {
    switch (activity.type) {
      case 'warning':
        return 'warning-activity';
      case 'failure':
        return 'failure-activity';
      case 'info':
        return 'info-activity';
      case 'download':
        return 'info-activity';
      case 'success':
        return 'success-activity';
      default:
        return 'default-activity';
    }
  }
}
