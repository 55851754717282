import { ChangeDetectorRef, Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ProfilePictureEditorComponent } from '../../../profile-picture-editor/profile-picture-editor.component';
import { ModalHubAccessService } from '../../access.service';

@Component({
    selector: 'app-profile-picture-modal',
    templateUrl: './profile-picture-modal.component.html',
    styleUrls: ['../modal.component.scss'],
    standalone: true
})
export class ProfilePictureModalComponent implements OnInit {
  constructor(private change: ChangeDetectorRef, private access: ModalHubAccessService) { }

  public self!: ComponentRef<ProfilePictureModalComponent>;
  @ViewChild("modalRef", {read: ViewContainerRef}) $ref!: ViewContainerRef;



  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.$ref.clear();
    const component = this.$ref.createComponent(ProfilePictureEditorComponent);
    component.instance.parent = this.self;
    document.addEventListener("keydown", this.onKeyboardPress)
    this.change.detectChanges();
  }

  destroyModal() { 
    this.access.closeModal();   
    this.self.destroy();
  }

  onKeyboardPress = (event: KeyboardEvent) => {
    if(event.key === 'Escape') {
      this.destroyModal()
    }
  }
}
