import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, EventEmitter, Input, Output, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DocumentFromTemplateComponent } from 'src/app/components/pages/selected-case-page/document/document-from-template/document-from-template.component';
import { ICaseBE } from '@datalex-software-as/datalex-client';
import { ModalHubAccessService } from '../../access.service';

@Component({
    selector: 'app-document-from-template-modal',
    templateUrl: './document-from-template-modal.component.html',
    styleUrls: ['../modal.component.scss'],
    standalone: true
})
export class DocumentFromTemplateModalComponent implements OnInit, AfterViewInit {
  constructor(private change: ChangeDetectorRef, private modalAccess: ModalHubAccessService) { }

  public self!: ComponentRef<DocumentFromTemplateModalComponent>;
  @ViewChild("modalRef", {read: ViewContainerRef}) $ref!: ViewContainerRef;
  @Input() case!: ICaseBE;


  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.$ref.clear();
    const component = this.$ref.createComponent(DocumentFromTemplateComponent);
    component.instance.case = this.case;
    component.instance.parent = this.self;
    document.addEventListener("keydown", this.onKeyboardPress)
    this.change.detectChanges();
    this.modalAccess.activeModal = component.instance
  }

  destroyModal() { 
    this.modalAccess.closeModal();   
    this.self.destroy();
  }

  onKeyboardPress = (event: KeyboardEvent) => {
    if(event.key === 'Escape') {
      this.destroyModal()
    }
  }

}
