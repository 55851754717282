import { registerLocaleData } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Subject, Subscription, filter, map, noop } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SystemCacheService } from './services/system-cache.service';
import localeNo from '@angular/common/locales/nb';
import { DeviceService } from './services/device.service';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ApplicationInsightsService } from './services/application-insights.service';
import { AuthGuardService } from './services/auth-guard.service';
import { SwUpdate } from '@angular/service-worker';


declare global {
  interface Window { windowId: string | null; }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  userActivity!: any;
  userInactive: Subject<void> = new Subject();
  isIframe = false;
  isPdfViewer = window.location.href.includes('pdfviewer');
  orientation!: string;
  resizeListener!: () => void;

  pageVisitTimer = 0;
  isTabletAndChrome: boolean = false;

  showNavBar = true;
  showToolBar = false;

  private subscriptions = new Subscription();

  constructor(
    public sys: SystemCacheService,
    private deviceService: DeviceService,
    private authGuard: AuthGuardService,
    private router: Router,
    private title: Title,
    private elRef: ElementRef,
    private ai: ApplicationInsightsService,
    private renderer: Renderer2,
    private updates: SwUpdate
  ) {

    this.updates.checkForUpdate().then((res) => {
      if (res) {
        this.updates.activateUpdate().then(() => document.location.reload());
      }
    }).catch(noop);

    this.updateVisibility(window.location.pathname);
    this.isIframe = window !== window.parent && !window.opener;

    this.setTimeout();

    this.userInactive.subscribe(() => {
      if (this.sys.loggedinUserContact) {
        //this.auth.logout();
      }
    });

    if (this.authGuard.IsAuthenticated() !== true) this.router.navigate(['/']);
    this.title.setTitle('Datalex');

    if (window.location.href.includes("standalone")) {
      this.elRef.nativeElement.style.marginTop = "0px";
    }
    registerLocaleData(localeNo, 'nb');
  }


  ngOnInit(): void {

    setInterval(() => {
      this.pageVisitTimer++;
    }, 1000);

    if (this.deviceService.getDeviceType() !== 'Desktop') {
      this.renderer.addClass(document.body, 'non-desktop');
    } else {
      this.renderer.removeClass(document.body, 'non-desktop');
    }

    try {
      this.router.events.subscribe((navEvent) => {
        if (navEvent instanceof NavigationEnd) {
          this.pageVisitTimer = 0;
        }

        if (navEvent instanceof NavigationStart) {
          if (this.sys.loggedInUser) {
            const navigationEvent = {
              user: this.sys.loggedInUser.Id,
              page: window.location.pathname.split('/')[1],
              timeUsage: this.pageVisitTimer
            };
            this.ai.appInsights.trackEvent({ name: "PageLoad" }, navigationEvent);
          }
        }
      });
    } catch (error) {
      console.error('Navigation tracking error:', error);
    }

    this.subscriptions.add(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
        const url = (event as NavigationEnd).urlAfterRedirects;
        this.updateVisibility(url);
      })
    );

    this.updateVisibility(this.router.url)
  }


  private updateVisibility(currentRoute: string): void {
    const isLoginPageOrPDFViewer = currentRoute === '/' || currentRoute.includes('pdfviewer') || currentRoute.includes('action=logout');
    const isDesktop = this.deviceService.getDeviceType() === 'Desktop';

    this.showNavBar = !isLoginPageOrPDFViewer;
    this.showToolBar = !isLoginPageOrPDFViewer && !isDesktop;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setTimeout() {
    if (!environment.production) return;
    this.userActivity = setTimeout(() => this.userInactive.next(), 14400000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}
