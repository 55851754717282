import { ChangeDetectorRef, Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ContactsComponent } from 'src/app/components/pages/contacts/contacts.component';
import { IContactExtendedBE } from '@datalex-software-as/datalex-client';
import { ModalHubAccessService } from '../../access.service';
import { ModalComponent, PublicProps } from '../../modal/modal.component';

@Component({
    selector: 'app-contact-search-modal',
    templateUrl: './contact-search-modal.component.html',
    styleUrls: ['../modal.component.scss'],
    standalone: true
})
export class ContactSearchModalComponent implements OnInit, Pick<PublicProps, keyof ModalComponent> {
  public self!: ComponentRef<ContactSearchModalComponent>;
  public title!: string;
  public contactName!: string;
  public searchResult: IContactExtendedBE[] = [];
  public canCreateNew!: boolean;
  public queryParams!: any;
  
  constructor(private change: ChangeDetectorRef, private modalAccess: ModalHubAccessService) {
  }

  @ViewChild("modalRef", {read: ViewContainerRef}) $ref!: ViewContainerRef;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void { 
      this.$ref.clear();
      const component = this.$ref.createComponent(ContactsComponent);
      component.instance.isModal = true;
      component.instance._contactName = this.contactName;
      component.instance.canCreateNewContact = this.canCreateNew;
      component.instance.data = this.searchResult;
      component.instance.canReset = this.searchResult ? true : false;
      component.instance.queryParams = this.queryParams;
      component.instance.self = this.self;
      document.addEventListener("keydown", this.onKeyboardPress)
      this.change.detectChanges();

      this.modalAccess.activeModal = component.instance
  }

  ngOnDestroy(): void {
    document.removeEventListener("keydown", this.onKeyboardPress);
    this.$ref.clear();
  }

  destroyModal() {    
    this.self.destroy();
  }

  onKeyboardPress = (event: KeyboardEvent) => {
    if(event.key === 'Escape') {
      this.self.destroy();
    }
  }

}
