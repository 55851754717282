<igx-grid igxPreventDocumentScroll #grid [data]="users" [displayDensity]="'compact'" [autoGenerate]="false"
  [allowFiltering]="true" [hideRowSelectors]="true" style="max-width: 100%;">
  <igx-column field="Username" header="Brukernavn" [sortable]="true" [dataType]="'string'" width="80px"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="Name" header="Navn" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="EmployedTo" header="Ansatt til" [sortable]="true" [dataType]="'date'" [pipeArgs]="dt.date"
    width="90px" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="LastLoginDate" header="Siste login" [sortable]="true" [dataType]="'date'" [pipeArgs]="dt.dateTime"
    width="124px" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="ServerVersion" header="Klient v." [sortable]="true" [dataType]="'string'" width="80px"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="DBVersion" header="DB v." [sortable]="true" [dataType]="'string'" width="80px"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="OSVersion" header="OS v." [sortable]="true" [dataType]="'string'" width="120px"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="OutlookVersion" header="Outlook v." [sortable]="true" [dataType]="'string'" width="120px"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="WordVersion" header="Word v." [sortable]="true" [dataType]="'string'" width="120px"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="EmailAddress" header="E" [sortable]="true" [dataType]="'string'" width="120px"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="ContactPhone" header="T" [sortable]="true" [dataType]="'string'" width="120px"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <!-- <igx-grid-footer class="grid-footer-container">
    <dlx-grid-item-count [isMobile]="isMobile" [current]="grid.totalRowsCountAfterFilter" [total]="users.length" />
  </igx-grid-footer> -->
</igx-grid>

<ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
  <grid-filter-input [grid]="grid" [column]="column"></grid-filter-input>
</ng-template>