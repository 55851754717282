import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

import { ISelectionEventArgs, IgxGridCell, IgxToggleActionDirective, IgxDropDownItemNavigationDirective, IgxDropDownComponent, IgxDropDownItemComponent } from '@infragistics/igniteui-angular';

import { RandomId } from 'src/app/util/RandomId';
import { DatalexClient } from '@datalex-software-as/datalex-client';
import { NgTemplateOutlet } from '@angular/common';

export interface IGridSelectChangeEvent {
  cell: IgxGridCell,
  value: ISelectionEventArgs
}

@Component({
    selector: 'app-grid-select',
    templateUrl: './grid-select.component.html',
    styleUrls: ['./grid-select.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, IgxToggleActionDirective, IgxDropDownItemNavigationDirective, IgxDropDownComponent, IgxDropDownItemComponent]
})

export class GridSelectComponent implements OnInit {
  constructor(private dlxClient: DatalexClient) { }
  
  @Input() cell!: IgxGridCell;
  @Input() datasource!: any[] | null;
  @Input() fieldName!: string;
  @Output() selectChange = new EventEmitter<IGridSelectChangeEvent>()
  options!: any[] | null;
  
  public itemHeight = 48;
  public itemsMaxHeight = 240;


  ngOnInit(): void {
    
  }

  id = RandomId();

  cellClick(filter: HTMLInputElement, event: Event){
    if(!this.datasource && this.datasource !== null) return
    if(this.datasource === null) {
      try {
        this.dlxClient["GetDocumentSubCategorysByCategory"](this.cell.row.data.DocumentCategoryId).subscribe({
          next: (res) => {
            this.datasource = res;
            this.options = this.datasource;
          } 
        })
      } catch (error) {
      }

    } else {
      this.options = this.datasource;
    }

    
    setTimeout(() => {
      filter.focus();
    }, 1)
    event.stopPropagation();
  }

  filterSelect(input: string, event: Event){  
    if(!this.datasource) return
    this.options = this.datasource.filter(i =>  (i[this.fieldName] as string).toUpperCase().includes(input.toUpperCase()))
  }

  selectionChanging(value: ISelectionEventArgs, cell: IgxGridCell) {
    this.selectChange.emit({cell, value})
  }
  
}
