<igx-tabs>
    <igx-tab-item>
        <igx-tab-header>
            <igx-tab-header-label>Timer</igx-tab-header-label>
        </igx-tab-header>
        <igx-tab-content>
            <!-- <app-hours-registration [data]="data"></app-hours-registration> -->
        </igx-tab-content>
    </igx-tab-item>
    <igx-tab-item>
        <igx-tab-header>
            <igx-tab-header-label>Omkostninger</igx-tab-header-label>
        </igx-tab-header>
        <igx-tab-content>
            <app-costs [data]="data"></app-costs>
        </igx-tab-content>
    </igx-tab-item>
</igx-tabs>