import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CaseBE } from 'src/app/classes/CaseBE';
import { ICaseBE } from '@datalex-software-as/datalex-client';
import { HoursRegistrationComponent } from 'src/app/components/pages/selected-case-page/charging/hours-registration/hours-registration.component';



@Component({
    selector: 'hour-registration-modal',
    templateUrl: './hour-registration-modal.component.html',
    styleUrls: ['../modal.component.scss'],
    standalone: true
})
export class HourRegistrationModalComponent implements OnInit, AfterViewInit, OnDestroy  {
  public case!: CaseBE;
  public date!: Date;
  public self!: ComponentRef<HourRegistrationModalComponent>;

  constructor(private change: ChangeDetectorRef) {
  }

  @ViewChild("modalRef", {read: ViewContainerRef}) $ref!: ViewContainerRef;

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    
    this.$ref.clear();
    const component = this.$ref.createComponent(HoursRegistrationComponent);
    component.instance.data = this.case;
    component.instance.date = this.date;
    document.addEventListener("keydown", this.onKeyboardPress)
    this.change.detectChanges();
  }

  ngOnDestroy(): void {
    
    document.removeEventListener("keydown", this.onKeyboardPress);
    this.$ref.clear();
  }

  destroyModal() {
    
    
    this.self.destroy();
  }

  onKeyboardPress = (event: KeyboardEvent) => {
    if(event.key === 'Escape') {
      this.self.destroy();
    }
  }

}
