import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { UserAreaEnum } from 'src/app/util/UserRightUtil';
import { NgClass } from '@angular/common';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
  standalone: true,
  imports: [NgClass]
})

export class ToolbarComponent implements OnInit {
  caseRights!: string;
  activeRoute: string = '';
  isLoginPage: boolean = false;

  private routerSubscription: Subscription;

  constructor(
    public sys: SystemCacheService,
    public rights: UserRightsProviderService,
    private router: Router,
    // private activatedRoute: ActivatedRoute
  ) {



    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activeRoute = event.urlAfterRedirects.startsWith('/') ? event.urlAfterRedirects.substring(1) : event.urlAfterRedirects;

        if (event.urlAfterRedirects === '/' || event.urlAfterRedirects.includes('/action=logout')) {
          this.isLoginPage = true;
        } else {
          this.isLoginPage = false;
        }
      }
    });
  }

  ngOnInit() {
    this.initCaseRights();
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  initCaseRights() {
    try {
      this.caseRights = this.rights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessName;
    } catch (error) {
      this.sys.isReady.subscribe({
        next: () => {
          this.caseRights = this.rights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessName;
        }
      })
    }
  }

  // setActiveRoute() {
  //   this.activeRoute = this.activatedRoute.snapshot.firstChild?.routeConfig?.path ?? ''; // this doesn't update the activeRoute when the route changes via the drwaer
  // }

  toolbarNavigation(route: string): void {
    this.activeRoute = route;
    this.router.navigate([route]);
  }
}

