import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Activity } from 'src/app/classes/Activity/Actvity';
import { DateDisplay } from 'src/app/util/DateDisplay';
import { ActivityLogService } from '../activity-log.service';
import { IgxIconComponent, IgxLinearProgressBarComponent } from '@infragistics/igniteui-angular';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'activity-log-overlay-item',
  templateUrl: './activity-log-overlay-item.component.html',
  styleUrls: ['./activity-log-overlay-item.component.scss'],
  standalone: true,
  imports: [CommonModule, IgxIconComponent, IgxLinearProgressBarComponent]
})

export class ActivityLogOverlayItemComponent implements OnInit, OnDestroy {
  @Input() activity!: Activity;

  activityText: string = '';
  activityDateTime: string = '';
  timeOut!: any;
  isLoading: boolean = true;

  constructor(private als: ActivityLogService) { }

  ngOnInit(): void {
    this.activityText = this.activity.message;
    this.activityDateTime = DateDisplay(this.activity.timestamp.toISOString());
    if (this.activity.userDismiss !== true) {
      if (this.activity.type === 'failure' || this.activity.type === 'success') this.timeOut = setTimeout(() => this.dismiss(), 4000);
      else if (this.activity.type !== 'info') this.timeOut = setTimeout(() => this.dismiss(), 1300);
      else this.isLoading = true;
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeOut);
  }
  get iconName() {
    switch (this.activity.type) {
      case 'warning': return 'warning';
      case 'failure': return 'error';
      case 'success': return 'done';
      case 'info': return 'info';
      default: return 'done';
    }
  }

  dismiss() {
    this.isLoading = false;
    setTimeout(() => {
      this.als.removeActivity(this.activity);
    }, 500);
  }


}