import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DatalexClient, IContactBE } from '@datalex-software-as/datalex-client';

@Injectable({
  providedIn: 'root'
})
export class ContactResolverService {

  constructor(private readonly dlxClient: DatalexClient) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IContactBE> {
    
    const id = route.params['id'];
    return this.dlxClient.GetContactById(id);
  }
}
