<igx-grid class="message_grid" igxPreventDocumentScroll #grid [data]="messages" [displayDensity]="'compact'"
  [autoGenerate]="false" [allowFiltering]="true" [hideRowSelectors]="true" (selected)="handleSelection($event)"
  [cellSelection]="'single'" [rowSelection]="'single'" style="width: 100%;">
  <igx-column field="Type" header="Type" [sortable]="true" [dataType]="'string'" width="200px"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="Procedure" header="Prosedyre" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="Description" header="Beskrivelse" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="MessageDate" header="Dato" [sortable]="true" [dataType]="'date'" [pipeArgs]="dateTimeOptions"
    width="124px" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column field="Username" header="Brukernavn" [sortable]="true" [dataType]="'string'" width="150px"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-grid-footer class="grid-footer-container">
    <dlx-grid-item-count [isMobile]="isMobile" [current]="grid.totalRowsCountAfterFilter" [total]="messages.length" />
  </igx-grid-footer>
</igx-grid>

<ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
  <grid-filter-input [grid]="grid" [column]="column"></grid-filter-input>
</ng-template>