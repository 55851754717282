import { Injectable } from "@angular/core";
import { ApplicationInsights, IPageViewTelemetry, IDependencyTelemetry, IEventTelemetry } from "@microsoft/applicationinsights-web";
import { environment } from "src/environments/environment";

export interface ITrackRequestTelemetry {
  method: string
  endpoint: string
  url: string
  duration: number
  responseCode: number
  success: boolean
  server: string
}


@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {

  appInsights: ApplicationInsights;
  pageViewTelemetry: IPageViewTelemetry;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appInsightsConfig.connectionString, // provided by Azure
        autoTrackPageVisitTime: true,
      },
    });

    this.pageViewTelemetry = {
      name: window.location.pathname.split('/')[1],
      uri: window.location.pathname,
    }

    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  logError(error: Error) {
    this.appInsights.trackException({ exception: error });
  }



  /**
   * Track custom events with specific data
   * @param eventName - Name of the custom event
   * @param properties - Additional properties for context
   */
  trackEvent(eventName: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: eventName }, properties);
  }

  /**
   * Track page view with route or name
   * @param pageName - Name of the page or route
   */
  trackPageView({ name, isLoggedIn }: { name: string, isLoggedIn: boolean }) {
    this.appInsights.trackPageView({ name, isLoggedIn });
  }

  /**
   * Track exception in the application
   * @param exception - Error object
   * @param properties - Additional properties for context
   */
  trackException(exception: Error, properties?: { [key: string]: any }) {
    this.appInsights.trackException({ exception }, properties);
  }

  /**
   * Track dependencies, such as external API calls
   * @param dependencyName - Name of the dependency
   * @param url - URL of the dependency
   * @param duration - Time taken by the dependency call
   * @param success - Was the call successful or not
   */
  trackDependency({ name, target, duration, success, responseCode }: IDependencyTelemetry) {
    this.appInsights.trackDependencyData({ id: crypto.randomUUID(), target, name, duration, success, responseCode });
  }


  trackRequest({ method, endpoint, url, duration, responseCode, success, server }: ITrackRequestTelemetry) {
    this.appInsights.trackEvent({ name: 'httpRequest' }, {
      method: method,
      url: url,
      endpoint,
      duration: duration.toString(),
      responseCode: responseCode.toString(),
      success: success,
      server
    });
  }

}