import { inject } from '@angular/core';
import { Route } from '@angular/router'
import { DatalexClient } from '@datalex-software-as/datalex-client';

function canActivateGuard() {
  const auth = inject(DatalexClient);
  return !!auth.token && !!auth.webURL
}
const R_MoneyLaunderingStatus: Route = {
  path: 'money-laundering-status',
  canActivate: [canActivateGuard],
  loadComponent: () => import('./money-laundering/money-laundering-status/money-laundering-status.component').then(c => c.MoneyLaunderingStatusComponent),
  data: {
    title: "Status hvitvasking",
    route: 'money-laundering-status',
    label: 'Status hvitvasking',
    icon: '',
  }
}


export const ReportsRootRoute: Route = {
  path: 'reports',
  canActivate: [canActivateGuard],
  loadComponent: () => import('./reports-menu/reports-menu.component').then(c => c.ReportsMenuComponent),
  children: [
    R_MoneyLaunderingStatus,
  ]
}