import { Component, Input, OnDestroy } from '@angular/core';
import { IUserInfoBE } from '@datalex-software-as/datalex-client';
import { Subscription } from 'rxjs';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { datePipes } from 'src/app/util/DatePipesUtil';
import { GridFilterInputComponent } from '../../../../UI/grid-filter-input/grid-filter-input.component';
import { IgxGridComponent, IgxColumnComponent, IgxFilterCellTemplateDirective } from '@infragistics/igniteui-angular';

@Component({
    selector: 'app-customer-users',
    templateUrl: './customer-users.component.html',
    styleUrls: ['./customer-users.component.scss'],
    standalone: true,
    imports: [IgxGridComponent, IgxColumnComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent]
})
export class CustomerUsersComponent implements OnDestroy {
  @Input() users: IUserInfoBE[] = [];
  public dt = datePipes;

  // isMobile!: boolean;

  // private subscriptions = new Subscription();

  // constructor(public screenSize: ScreenSizeService) {
  //   this.subscriptions.add(this.screenSize.isMobile$.subscribe(isMobile => {
  //     this.isMobile = isMobile;
  //   }));
  // }

  ngOnDestroy(): void {
    // this.subscriptions.unsubscribe();
  }

}
