import { Component, forwardRef, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { RandomId } from 'src/app/util/RandomId';
import { NgIf } from '@angular/common';

@Component({
    selector: 'dlx-textarea',
    templateUrl: './dlx-textarea.component.html',
    styleUrls: ['./dlx-textarea.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DlxTextareaComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [FormsModule, NgIf]
})
export class DlxTextareaComponent implements OnInit, ControlValueAccessor {
  @ViewChild('input') $input!: any;
  @Input() label!: string;
  @Input() rows!: number;

  @Input() disabled: boolean = false;
  @Input() lockable: boolean = false;
  @Input() locked: boolean = false;

  @Output() onChange = new EventEmitter<string>()


  defaultId = RandomId();
  _value!: any;

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.propagateChange(this.value);
    this.onChange.emit(this.value);
  }



  constructor(public screen: ScreenDimensionService) { }


  ngOnInit(): void {
    this.checkRequiredProps(this.label);
  }

  checkRequiredProps(label: string) {
    if(!label) {
       throw new Error("Property 'label' is required on dlx-textarea");
    }
  }

  //NGMODEL stuff
  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
      if( value !== null) {
        this.updateDisplay(value)
      }
    }
  }
  propagateChange = (_: any) => {};

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}

  updateDisplay(value: string) {
    if(!value) {
      this.$input.nativeElement.value = null;
    } else {
      this.$input.nativeElement.value = value;
    }
  }
}
