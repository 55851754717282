import { Inject, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthTestService } from './auth-test.service';

export const authguardTestGuard: CanActivateFn = (route, state) => {
  const authtestservice: AuthTestService = inject(AuthTestService);
  const router = inject(Router);

  if (authtestservice.logedAsMaster()) {

    return true;

  } else {

    return false;
  }
};
