import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ICaseBE } from '@datalex-software-as/datalex-client';
import { UploadDocumentGridComponent } from '../../../../pages/selected-case-page/document/upload-document-grid/upload-document-grid.component';

@Component({
    selector: 'app-document-upload-modal',
    templateUrl: './document-upload-modal.component.html',
    styleUrls: ['./document-upload-modal.component.scss'],
    standalone: true
})
export class DocumentUploadModalComponent implements OnInit, AfterViewInit, OnDestroy {
  public files!: FileList;
  public case!: ICaseBE;
  public self!: ComponentRef<DocumentUploadModalComponent>;
  constructor(private factory: ComponentFactoryResolver, private change: ChangeDetectorRef) { }

  @ViewChild("modalRef", {read: ViewContainerRef}) $ref!: ViewContainerRef;

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    
    this.$ref.clear();
    const componentFactory = this.factory.resolveComponentFactory(UploadDocumentGridComponent)
    const component = this.$ref.createComponent(componentFactory);
    component.instance.case = this.case;
    component.instance.files = this.files;
    component.instance.self = this.self;
    document.addEventListener("keydown", this.onKeyboardPress)
    this.change.detectChanges();
  }

  ngOnDestroy(): void {
    
    document.removeEventListener("keydown", this.onKeyboardPress);
    this.$ref.clear();
  }

  destroyModal() {
    
    
    this.self.destroy();
  }

  onKeyboardPress = (event: KeyboardEvent) => {
    if(event.key === 'Escape') {
      this.self.destroy();
    }
  }
}
