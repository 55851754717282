<div>
    <div class="modal-body">
        <div class="modal-header">
            <span class="modal-header-title">
                Dokumentkategorier:
            </span>
            <i class="material-icons modal-header-btn" id="modalCloseBtn" (click)="destroyModal()">close</i>
        </div>
        <div class="modal-content" #modalRef></div>
    </div>
</div>
