import { Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalId } from 'src/app/util/ModalTypeIdEnum';
import { ActiveModal, ModalAccessParams, ModalHubAccessService } from './access.service';
import { HourRegistrationModalComponent } from 'src/app/components/UI/modal-hub/modals/hour-registration-modal/hour-registration-modal.component';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { DocumentUploadModalComponent } from './modals/document-upload-modal/document-upload-modal.component';
import { DocumentDetailsModalComponent } from './modals/document-details-modal/document-details-modal.component';
import { DatalexClient } from '@datalex-software-as/datalex-client';
import { DocumentFromTemplateModalComponent } from './modals/document-from-template-modal/document-from-template-modal.component';
import { ProfilePictureModalComponent } from './modals/profile-picture-modal/profile-picture-modal.component';
import { CaseBE } from 'src/app/classes/CaseBE';
import { ContactSearchModalComponent } from './modals/contact-search-modal/contact-search-modal.component';
import { DocumentCategoryModalComponent } from './modals/document-category-modal/document-category-modal.component';
import { CustomerModalComponent } from './modals/customer-modal/customer-modal.component';


type TModal = {
  self: ComponentRef<unknown>,
  data: any
}

@Component({
    selector: 'modal-hub',
    templateUrl: './modal-hub.component.html',
    styleUrls: ['./modal-hub.component.scss'],
    standalone: true
})
export class ModalHubComponent implements OnInit {



  constructor(private access: ModalHubAccessService, private sys: SystemCacheService, private dlxClient: DatalexClient) {
    this.access.onOpenModal().subscribe((modalParams) => {
      this.openModal(modalParams)
    });
  }

  @ViewChild("modalRef", { read: ViewContainerRef }) $modalRef!: ViewContainerRef;

  ngOnInit(): void {

  }


  openModal(params: ModalAccessParams) {
    switch (params.type) {
      case ModalId.HourRegistration:
        return this.openHourRegistrationModal(params)
      case ModalId.DocumentUpload:
        return this.openDocumentUploadModal(params)
      case ModalId.DocumentDetails:
        return this.openDocumentDetailsModal(params)
      case ModalId.DocumentCategory:
        return this.openDocumentCategoryModal(params)
      case ModalId.DocumentFromTemplate:
        return this.openDocumentFromTemplateModal(params)
      case ModalId.ProfilePicture:
        return this.openProfilePictureModal()
      case ModalId.ContactSearch:
        return this.openContactSearchModal(params);
      case ModalId.Customer:
        return this.openCustomerModal(params);
      default:
        console.error('no modal for this action')
        return null
    }
  }

  async openContactSearchModal(params: ModalAccessParams) {
    this.$modalRef.clear();
    const { title, contactName, searchResult, canCreateNew, queryParams } = params.data;
    const component = this.$modalRef.createComponent(ContactSearchModalComponent);
    component.instance.title = title;
    component.instance.contactName = contactName;
    component.instance.searchResult = searchResult;
    component.instance.canCreateNew = canCreateNew;
    component.instance.queryParams = queryParams;
    component.instance.self = component;

    return component.instance;
  }



  async openHourRegistrationModal(params: ModalAccessParams) {
    this.dlxClient.GetCase(params.data.caseID, false).subscribe((response) => {
      this.$modalRef.clear();
      const component = this.$modalRef.createComponent(HourRegistrationModalComponent);
      component.instance.self = component;
      component.instance.case = new CaseBE(response);
      component.instance.date = params.data.date;

      return component.instance;
    })
  }

  async openDocumentUploadModal(params: ModalAccessParams) {
    this.$modalRef.clear();
    const component = this.$modalRef.createComponent(DocumentUploadModalComponent);
    component.instance.self = component;
    component.instance.case = params.data.case;
    component.instance.files = params.data.files;

    return component.instance;
  }


  async openDocumentDetailsModal(params: ModalAccessParams) {
    this.dlxClient.GetDocument(params.data).subscribe((response) => {
      this.$modalRef.clear();
      const component = this.$modalRef.createComponent(DocumentDetailsModalComponent);
      component.instance.self = component;
      component.instance.document = response;

      return component.instance;
    })
  }

  async openDocumentFromTemplateModal(params: ModalAccessParams) {
    this.$modalRef.clear();
    const component = this.$modalRef.createComponent(DocumentFromTemplateModalComponent);
    component.instance.self = component;
    component.instance.case = params.data.case;

    return component.instance;
  }

  async openProfilePictureModal() {
    this.$modalRef.clear();
    const component = this.$modalRef.createComponent(ProfilePictureModalComponent);
    component.instance.self = component;

    return component.instance;
  }

  async openDocumentCategoryModal(params: ModalAccessParams) {
    this.$modalRef.clear();
    const component = this.$modalRef.createComponent(DocumentCategoryModalComponent);
    component.instance.self = component;
    component.instance.data = params.data;

    return component.instance;
  }
  async openCustomerModal(params: ModalAccessParams) {
    this.$modalRef.clear();
    const component = this.$modalRef.createComponent(CustomerModalComponent);
    component.instance.self = component;
    component.instance.data = params.data;

    return component.instance;
  }
}

