<div class="login-top">
  <div class="logo-wrapper">
    <img src="../../../../assets/images/datalex_dark_logo.png" alt="datalex logo">
    <h3>web</h3>
  </div>
  <ng-container *ngIf="twoFactor === false; then loginForm else mfaForm"></ng-container>
</div>

<div class="login-bottom">
  <span>&copy; {{ year }} Datalex Software AS</span>
  <span id="appVersion"></span>
</div>

<div class="cookie-snackbar">
  <igx-dialog #cookiePolicyDialog [closeOnOutsideSelect]="false" style="max-width: 600px;" leftTopLabel="OK"
    (leftBottomSelect)="cookiePolicyDialog.close();">
    <igx-dialog-title>
      <div class="dialog-title-container">
        <div class="dialog-title">Cookie Policy</div>
        <igx-icon family="material-symbols-outlined" (click)="cookiePolicyDialog.close()">close</igx-icon>
      </div>
    </igx-dialog-title>
    <p style="padding: 1rem 0;">Denne nettsiden benytter cookies for funksjonalitet. Samtykke antas ved pålogging.</p>
    <igx-dialog-actions>
      <button igxButton (click)="cookiePolicyDialog.close()" igxButton="raised" igxButtonColor="white"
        [style.background]="'#AEC965'" igxRipple="white">Jeg forstår</button>
    </igx-dialog-actions>
  </igx-dialog>
  <p>Denne nettsiden benytter cookies for funkjonalitet, les mer <span style="color:blue; cursor: pointer;"
      (click)="cookiePolicyDialog.open()">her</span></p>
</div>



<ng-template #loginForm>
  <form class="login-form">
    <label for="username">Brukernavn</label>
    <input type="text" name="username" [(ngModel)]="username">
    <label for="password">Passord</label>
    <input id="passwordInput" type="password" name="password" [(ngModel)]="password">
    <form-error-message *ngIf="errMessage">Feil brukernavn eller passord.</form-error-message>
    <ng-container *ngIf="!loginLoading; then loginBtn; else loading"></ng-container>
    <span id="pdfviewer"></span>
  </form>
</ng-template>

<ng-template #mfaForm>
  @if(twoFactorNotEnabled) {
    <div class="twoFactorNotEnabled_message">
      <h1>For å ta i bruk DatalexWeb må to-faktor autentisering være aktivert</h1>
      <p>Kontakt Datalex Software AS for oppsett av to-faktor.</p>
      <div class="back_wrapper" (click)="returnToLogin()">
        <span class="material-symbols-outlined">arrow_back</span>
        <span>Gå tilbake</span>
      </div>
    </div>
  } @else {
  <form class="login-form">
    <label for="mfaCode">To-faktor:</label>

    <ng-container *ngIf="maxRetry - tries > 0">
      <otp-input id="mfa-input" [ngStyle]="{ 'visibility': loginLoading ? 'hidden' : 'visible' }" #otpInput
        (completed)="onOtpComplete($event)" />
      @if(loginLoading) {
      <ng-container *ngTemplateOutlet="loading"></ng-container>
      }
    </ng-container>
    <div style="text-align: center;" *ngIf="mfaError">Feil kode, du har {{ maxRetry - tries}} forsøk igjen.</div>
    <span class="sendNewMfa" *ngIf="!loginLoading" (click)="sendNewMfa()">Send ny kode</span>
  </form>
}
</ng-template>



<ng-template #loginBtn>
  <input type="submit" id="login_btn" (click)="onSubmit()" value="Logg inn">
</ng-template>


<ng-template #loading>
  <div class="loginloader">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-template>