import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule, Route, CanActivateFn } from '@angular/router';
import { ContactComponent } from './components/pages/contact/contact.component';
import { LoginComponent } from './components/pages/login/login.component';
import { SelectedCasePageComponent } from './components/pages/selected-case-page/selected-case-page.component';
import { CaseResolverService } from './services/case-resolver.service';
import { ContactResolverService } from './services/contact-resolver.service';
import { NoPageComponent } from './components/pages/no-page/no-page.component';
import { authguardTestGuard } from './authguard-test.guard';
import { DatalexClient } from '@datalex-software-as/datalex-client';
import { UserRightsProviderService } from './services/user-rights-provider.service';
import { RegisterRootRoute } from './components/UI/register-editors/register-routes.module';
import { ReportsRootRoute } from './components/UI/reports/reports-routes.module';


function canActivateGuard() {
  const auth = inject(DatalexClient);
  return !!auth.token && !!auth.webURL
}

const canActivateCase: CanActivateFn = async (): Promise<boolean> => {
  const userRightsProviderService = inject(UserRightsProviderService);
  return await userRightsProviderService.checkCaseAccessLevel();
};
const canActivateContacts: CanActivateFn = async (): Promise<boolean> => {
  const userRightsProviderService = inject(UserRightsProviderService);
  return await userRightsProviderService.checkContactAccessLevel({ newContact: false });
};
const canActivateNewContacts: CanActivateFn = async (): Promise<boolean> => {
  const userRightsProviderService = inject(UserRightsProviderService);
  return await userRightsProviderService.checkContactAccessLevel({ newContact: true });
};

const loginRoute: Route = {
  path: '',
  component: LoginComponent
}
const myCasesRoute: Route = {
  path: "shortlist",
  loadComponent: () => import('./components/pages/my-cases/my-cases.component').then(c => c.MyCasesComponent),
  canActivate: [canActivateGuard],
}

const findCaseRoute: Route = {
  path: "findcase",
  loadComponent: () => import('./components/pages/find-case/find-case.component').then(c => c.FindCaseComponent),
  canActivate: [canActivateGuard, canActivateCase],
}

const caseRoute: Route = {
  path: "case/:id",
  loadComponent: () => import('./components/pages/selected-case-page/selected-case-page.component').then(c => c.SelectedCasePageComponent),
  canActivate: [canActivateGuard, canActivateCase],
  canDeactivate: [(component: SelectedCasePageComponent) => {
    return component.caseData.isChanged ? confirm("Du har ulagrede endringer, vil du fortsette uten å lagre?") : true
  }],
  resolve: { case: CaseResolverService }
}

const findContactRoute: Route = {
  path: "findcontact",
  loadComponent: () => import('./components/pages/contacts/contacts.component').then(c => c.ContactsComponent),
  canActivate: [canActivateGuard, canActivateContacts],

}


const contactRoute: Route = {
  path: "contact/:id",
  loadComponent: () => import('./components/pages/contact/contact.component').then(c => c.ContactComponent),
  canActivate: [canActivateGuard, canActivateContacts],
  canDeactivate: [(component: ContactComponent) => {
    return component.contactBe.isChanged ? confirm("Du har ulagrede endringer, vil du fortsette uten å lagre?") : true
  }],
  resolve: { contact: ContactResolverService }
}

const newContactRoute: Route = {
  path: "newcontact",
  loadComponent: () => import('./components/pages/new-contact/new-contact.component').then(c => c.NewContactComponent),
  canActivate: [canActivateGuard, canActivateNewContacts],
}

const customers: Route = {
  path: 'customers',
  canActivate: [authguardTestGuard],
  // component: CustomersComponent,
  loadComponent: () => import('./components/pages/customers/customers.component').then(c => c.CustomersComponent),
}

const messages: Route = {
  path: 'messages',
  loadComponent: () => import('./components/pages/settings/messages/messages.component').then(c => c.MessagesComponent),
}
const sharepointConfig: Route = {
  path: 'sharepointconfig',
  loadComponent: () => import('./components/pages/settings/sharepoint-config/sharepoint-config.component').then(c => c.SharepointConfigComponent),
}
const sharepointUser: Route = {
  path: 'sharepointuser',
  loadComponent: () => import('./components/pages/settings/sharepoint-user/sharepoint-user.component').then(c => c.SharePointUserComponent),
}



const settings: Route = {
  path: 'settings',
  canActivate: [canActivateGuard],
  loadComponent: () => import('./components/pages/settings/settings.component').then(c => c.SettingsComponent),
  children: [
    messages,
    sharepointConfig,
    sharepointUser,
    RegisterRootRoute,
    ReportsRootRoute
  ]
}


export const documentTopics: Route[] = [
  {
    path: 'documentmanagment',
    data: { title: "Dokumentbehandling" },
    loadComponent: () => import(`./components/pages/help/pages/dokumentbehandling/page.component`).then(c => c.PageComponent),
  }
]

export const hourRegistration: Route[] = [
  {
    path: 'chargning',
    data: { title: "Belastning" },
    loadComponent: () => import(`./components/pages/help/pages/belastning/page.component`).then(c => c.PageComponent),
  }
]
export const helpHome: Route[] = [
  {
    path: 'home',
    data: { title: "Hjem" },
    loadComponent: () => import(`./components/pages/help/pages/home/page.component`).then(c => c.PageComponent),
  }
]
export const releaseLog: Route[] = [
  {
    path: 'news',
    data: { title: "Nyheter" },
    loadComponent: () => import(`./components/pages/help/pages/release-log/page.component`).then(c => c.PageComponent),
  }
]
export const usefulTips: Route[] = [
  {
    path: 'usefultips',
    data: { title: "Tips og triks " },
    loadComponent: () => import(`./components/pages/help/pages/useful-tips/page.component`).then(c => c.PageComponent),
  }
]

export const topics = [...helpHome, ...releaseLog, ...usefulTips, ...documentTopics, ...hourRegistration]

const help: Route = {
  path: 'help',
  loadComponent: () => import('./components/pages/help/help-page.component').then(c => c.HelpPageComponent),
  children: [...topics]
}


const pdfViewer: Route = {
  path: 'pdfviewer/:id',
  loadComponent: () => import('./components/pages/pdf-viewer/pdf-viewer.component').then(c => c.PdfViewerComponent),
}
const pdfViewerStandalone: Route = {
  path: 'pdfstandalone/:cookieId',
  loadComponent: () => import('./StandaloneComponents/sapdfviewer/standalone-pdfviewer.component').then(c => c.StandAlonepdfviewerComponent),
}


const inbox: Route = {
  path: 'inbox',
  loadComponent: () => import('./components/pages/test-page/email/inbox/inbox.component').then(c => c.InboxComponent),
}
const drafts: Route = {
  path: 'drafts',
  loadComponent: () => import('./components/pages/test-page/email/drafts/drafts.component').then(c => c.DraftsComponent),
}
const sent: Route = {
  path: 'sent',
  loadComponent: () => import('./components/pages/test-page/email/sent/sent.component').then(c => c.SentComponent),
}

const test: Route = {
  path: 'test',
  canActivate: [canActivateGuard],
  loadComponent: () => import('./components/pages/test-page/test-page.component').then(c => c.TestPageComponent),
  children: [
    inbox,
    drafts,
    sent,
  ]
}



const routes: Routes = [
  loginRoute,
  myCasesRoute,
  caseRoute,
  findCaseRoute,
  findContactRoute,
  contactRoute,
  newContactRoute,
  pdfViewer,
  pdfViewerStandalone,
  customers,
  settings,
  help,
  test,
  { path: '404', component: NoPageComponent },
  { path: '**', redirectTo: '/404' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

