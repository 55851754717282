<div class="document-buttonRow">
  <label class="docRow-btn" (click)="onGridSave()">
    <span>Lagre</span>
  </label>
  <label class="docRow-btn" (click)="onRowRemove()">
    <span>Fjern</span>
  </label>
</div>
<igx-grid *ngIf="gridContent" igxPreventDocumentScroll #caseRoleGrid id="upload-document-grid"
  class="upload-document-grid remove-shadows" [data]="gridContent" [displayDensity]="deviceService.gridDensity"
  [autoGenerate]="false" [width]="'null'" [cellSelection]="'single'" [rowSelection]="'multiple'"
  [hideRowSelectors]="false" (onRowSelectionChange)="handleRowSelection($event)">
  <igx-column field="title" header="Tittel" [sortable]="true" [dataType]="'string'" [editable]="true"></igx-column>
  <igx-column field="lastModified" header="Sist endret" [sortable]="true" [dataType]="'date'"></igx-column>
  <igx-column field="sizeInMB" header="Størrelse" [sortable]="true" [dataType]="'string'"></igx-column>
  <igx-column field="name" header="Navn" [sortable]="true" [dataType]="'string'"></igx-column>
</igx-grid>