@if(!isLoginPage){
<div class="mobile-toolbar">
  <div (click)="toolbarNavigation('findcontact')"
    [ngClass]="activeRoute === 'findcontact' ? 'mobile-toolbar-item--active' : 'mobile-toolbar-item'"
    [ngClass]="caseRights === 'NONE' ? 'mobile-toolbar-item-no-access' : 'mobile-toolbar-item'">
    <span class="material-symbols-outlined spacer">person_search</span>
    <span>Kontakter</span>
  </div>
  <div id="toolbar_shortlist" (click)="toolbarNavigation('shortlist')"
    [ngClass]="activeRoute === 'shortlist' ? 'mobile-toolbar-item--active' : 'mobile-toolbar-item'">
    <span class="material-symbols-outlined spacer">list</span>
    <span>Min Saksliste</span>
  </div>
  <div (click)="toolbarNavigation('findcase')"
    [ngClass]="activeRoute === 'findcase' ? 'mobile-toolbar-item--active' : 'mobile-toolbar-item'"
    [ngClass]="caseRights === 'NONE' ? 'mobile-toolbar-item-no-access' : 'mobile-toolbar-item'">
    <span class="material-symbols-outlined spacer cases">search</span>
    <span>Saker</span>
  </div>
</div>
}