import { Injectable, ErrorHandler } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ApplicationInsightsService } from "../services/application-insights.service";
import { DatalexExceptionHandlerService } from "../services/datalex-exception-handler.service";
import { InformationOverlayService } from "../components/UI/info-overlay/information-overlay.service";
import { RandomId } from "../util/RandomId";
import { environment } from "src/environments/environment";
import { MessagesService } from "../components/pages/settings/messages/messages.service";
import { DatalexClient } from "@datalex-software-as/datalex-client";
import { SystemCacheService } from "../services/system-cache.service";
import { toLocalIsoString } from "../util/DatalexDateTime";


@Injectable({
  providedIn: "root",
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private ai: ApplicationInsightsService, 
    private dlxEx: DatalexExceptionHandlerService, 
    private info: InformationOverlayService, 
    private messageService: MessagesService,
    private dlxClient: DatalexClient,
    private sys: SystemCacheService
  ) { }

  suppressErrors = true;

  handleError(error: Error | HttpErrorResponse) {
    

    if (error instanceof HttpErrorResponse) {
      // Server error
      this.ai.trackException(error);
      this.dlxEx.exception(error)
      this.info.addMessage({ id: RandomId(), message: error.message, sender: "GlobalErrorHandler", type: 2 })

    } else {
      if (error.message && error.message.includes("ASSERTION ERROR")) return
      this.ai.trackException(error);
      
      if (assertOutdatedChunks(error)) {
        this.info.addMessage({ id: RandomId(), message: "Ny versjon av DatalexWeb tilgjengelig, oppfrisk nettsiden", sender: "GlobalErrorHandler", type: 3 })
        return;
      }

      
      if(window.location.href.includes("localhost") === false) {
        this.dlxClient.NewUtilTraceMessages().subscribe({
          next: (msg) => {
            msg.IsNew = true;
            msg.Procedure = "Global.Caught.UnspecifiedError";
            msg.Type = "Datalex.WebApp";
            msg.Description = getErrorMessage(error);
            msg.Screenshot = null;
            msg.MessageDate = toLocalIsoString(+new Date());
            msg.Username = this.sys.loggedInUser.Username;
            msg.Count = null;
  
            this.dlxClient.SaveUtilTraceMessages([msg]).subscribe({
              next: (res) => {
              }
            })
          }
        })
      }


      
      if (this.suppressErrors) return;
      this.messageService.appendMessages({ id: RandomId(), title: error.name ?? "Error", content: error.stack ?? error.message, type: "error" });


      if (!environment.production) {
        
        this.info.addMessage({ id: RandomId(), message: String(error) ?? "Noe gikk galt og uten mer informasjon", sender: "GlobalErrorHandler", type: 2 })
      }
    }
  }
}

function getErrorMessage(error: Error){
  if(error.stack && error.message) return error.message + '\n' + "stack:" + '\n' + error.stack;
  else if(error.message) return "No stack, message:" + '\n' + error.message;
  else if(error.stack) return "No message, stack:" + '\n' +  error.stack;
  return "Unspesified error, no message or stack";
}

function assertOutdatedChunks(error: Error) {
  const match = 'Failed to fetch dynamically imported module'.toLowerCase();
  if (error.name === 'TypeError') {
    if (error.stack?.toLowerCase().includes(match) || error.message.toLowerCase().includes(match)) {
      return true
    }
    if (error.message.includes(`\'text/html\' is not a valid JavaScript MIME type`)) {
      return true
    }
  }

  return false
}
