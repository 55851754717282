import { Component, OnInit, AfterViewInit, Input, Output, HostListener, EventEmitter, ElementRef } from '@angular/core';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-items.component.html',
    styleUrls: ['./menu-items.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class MenuItemsComponent implements OnInit, AfterViewInit {

  @Input() icon!: string;
  @Input() label!: string;
  @Input() reverse: boolean = false;
  @Input() enabled: boolean = true;
  @Output() onClick = new EventEmitter();

  constructor(private elRef:ElementRef) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
  
    this.elRef.nativeElement.style.pointerEvents  = this.enabled ? 'initial' : 'none';
    this.elRef.nativeElement.style.color = this.enabled ? '#333' : '#BCBCBC';
    this.elRef.nativeElement.style.borderColor  = this.enabled ? '#0099ff52' : '#BCBCBC';
    
  }

  @HostListener('click') click() {
    this.onClick.emit(null);
  }

}
