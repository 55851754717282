import { Component, OnInit } from '@angular/core';
import { IInformationMessage, InformationOverlayService } from './information-overlay.service';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-information-overlay',
    templateUrl: './information-overlay.component.html',
    styleUrls: ['./information-overlay.component.scss'],
    standalone: true,
    imports: [NgFor]
})
export class InformationOverlayComponent implements OnInit {
  constructor(public info: InformationOverlayService) { }

  ngOnInit(): void {
  }

  classGenerator(type: 0 | 1 | 2 | 3) {
    switch(type) {
      case 0:
        return "info"
      case 1:
        return "warn"
      case 3: 
        return "news"
      case 2: 
      default:
        return "error"  
        
    }
  }

  refresh() {
    this.info.news = [];
    location.reload();
  }

}
