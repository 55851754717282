import { Component, ComponentRef, Input, OnInit } from '@angular/core';
//import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DatalexClient, IUserPictureBE } from '@datalex-software-as/datalex-client';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { ProfilePictureModalComponent } from '../modal-hub/modals/profile-picture-modal/profile-picture-modal.component';
import { IgxButtonDirective } from '@infragistics/igniteui-angular';
import { NgIf } from '@angular/common';

interface IGetPic {
  GetUserPictureByUserIdResult: null
}

@Component({
    selector: 'app-profile-picture-editor',
    templateUrl: './profile-picture-editor.component.html',
    styleUrls: ['./profile-picture-editor.component.scss'],
    standalone: true,
    imports: [NgIf, IgxButtonDirective]
})
export class ProfilePictureEditorComponent implements OnInit {
  constructor(private dlxClient: DatalexClient, private sys: SystemCacheService) { }

  @Input() parent!: ComponentRef<ProfilePictureModalComponent>;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  filename!: string;

  ngOnInit(): void {
  }

  /*
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
*/
  cropperReady() {

  }

  loadImageFailed() {
    // show message
  }

  fileChangeEvent(event: Event): void {
    // @ts-ignore
    const { files } = event.target;
    this.imageChangedEvent = event;

    if (files) {
      this.filename = files[0].name;
    }
  }

  saveImageCaller() {
    this.dlxClient.GetUserPictureByUserId(this.sys.loggedInUser.Id).subscribe({
      next: (res) => {
        let response = res as unknown as IGetPic;

        if (response.GetUserPictureByUserIdResult === null) {
          this.newUserPicture();
        } else {
          this.saveImage(response as unknown as IUserPictureBE);
        }
      }
    })
  }

  newUserPicture() {
    this.dlxClient.NewUserPicture().subscribe({
      next: this.saveImage.bind(this),
    })
  }

  saveImage(newPicture: IUserPictureBE) {
    let pictureBE = newPicture;
    pictureBE.UserId = this.sys.loggedInUser.Id;
    pictureBE.Picture = this.croppedImage;

    this.dlxClient.SaveUserPicture(newPicture).subscribe({
      next: (response) => {
        this.sys.setProfilePicture(response);
      },
    })

    this.parent.destroy();
  }

}
