import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { NgFor } from '@angular/common';
import { IgxSelectComponent, IgxLabelDirective, IgxSelectItemComponent, IgxSelectHeaderDirective } from '@infragistics/igniteui-angular';

@Component({
    selector: 'igx-select-wrapper',
    templateUrl: './igx-select-wrapper.component.html',
    styleUrls: ['./igx-select-wrapper.component.scss'],
    standalone: true,
    imports: [IgxSelectComponent, IgxLabelDirective, NgFor, IgxSelectItemComponent, IgxSelectHeaderDirective]
})
export class IgxSelectWrapperComponent implements OnInit {
  constructor(public cdr: ChangeDetectorRef, public element: ElementRef) {
  }

  public selected!: string;
  public fruits!: { type: string; delivery: string }[];

  public fruitsDS: { type: string; delivery: string }[] = [
    { type: 'Apple', delivery: 'boat' },
    { type: 'Banana', delivery: 'flight' },
    { type: 'Blackberry', delivery: 'train' },
    { type: 'Blueberry', delivery: 'train' },
    { type: 'Coconut', delivery: 'flight' },
    { type: 'Cherry', delivery: 'boat' },
    { type: 'Grape', delivery: 'train' },
    { type: 'Japanese plum', delivery: 'flight' },
    { type: 'Pineapple', delivery: 'flight' },
    { type: 'Melon', delivery: 'flight' },
    { type: 'Orange', delivery: 'boat' },
    { type: 'Pear', delivery: 'train' },
    { type: 'Peach', delivery: 'boat' },
    { type: 'Pineapple', delivery: 'flight' },
    { type: 'Pomegranate', delivery: 'flight' },
    { type: 'Watermelon', delivery: 'train' }
  ];


  public ngOnInit() {
    this.fruits = this.fruitsDS;
  }

  public filter(target: EventTarget | null) {
    let value = (target as HTMLButtonElement).title;
    if (this.selected === value) {
      this.fruits = this.fruitsDS;
      this.selected = 'allData';
      this.cdr.detectChanges();
      return;
    }
    this.selected = value;
    this.fruits = this.fruitsDS.filter(fruit => fruit.delivery === value);
    this.cdr.detectChanges();
  }
}
