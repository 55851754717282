import { Component, OnInit, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { DatalexClient } from '@datalex-software-as/datalex-client';
import { CaseBE } from 'src/app/classes/CaseBE';
import { DatalexMsalService } from 'src/app/services/datalex-msal.service';
import { IDocumentLimitedGrid, DocumentGridComponent } from './document-grid/document-grid.component';

export interface IOpenDocument {
  documentId: string,
  checkOut: boolean, 
  allowMultiEdit: boolean, 
}


interface IDocumentSelectionState {
  document: IDocumentLimitedGrid | null;
  allowMultiEdit: boolean;
  checkOut: boolean;
  reset: () => void;
  set: (data: TStateProps) => void;
}

type TStateProps = Omit<Partial<IDocumentSelectionState>, 'set' | 'reset'>;

class DocumentState implements IDocumentSelectionState {
  constructor(document?: IDocumentLimitedGrid){
    this.document = document ? document : null;
  }

  document: IDocumentLimitedGrid | null = null;
  allowMultiEdit: boolean = false;
  checkOut: boolean = false;

  reset = (): void => {
    this.document = null;
    this.allowMultiEdit = false;
    this.checkOut = false;
  };

  set = (data: TStateProps): IDocumentSelectionState => {
    Object.entries(data).forEach(([key, value]) => {
      (this as any)[key] = value;
    });

    return this
  };
}


 

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss'],
    standalone: true,
    imports: [DocumentGridComponent]
})
export class DocumentComponent implements OnInit {

  router = inject(Router);
  dlxClient = inject(DatalexClient);
  dlxMsal = inject(DatalexMsalService);


  @Input() data!: CaseBE;

  redirectUri: string = ""
  ngOnInit(): void {
    this.redirectUri = this.router.url;
  }

  state = new DocumentState()



  onGridSelection(row: IDocumentLimitedGrid){
    this.getDocumentOpener("msg")
  }

  openWord({documentId, checkOut, allowMultiEdit}: IOpenDocument) {
   
  }

  openMSG({documentId, checkOut, allowMultiEdit}: IOpenDocument) {

  }

  openPDF({documentId, checkOut, allowMultiEdit}: IOpenDocument) {

  }

  openDefault({documentId, checkOut, allowMultiEdit}: IOpenDocument) {
    
  }

  getDocumentOpener(fileExt: string){
    this.dlxMsal.useToken(this.redirectUri).subscribe({
      next: (accessToken: string) => {
        switch(fileExt.toLowerCase()) {
          case 'docx':
            return this.openWord.bind(this);
          case 'msg':
            return this.openMSG.bind(this);
          case 'pdf':
            return this.openPDF.bind(this);
          default:
            return this.openDefault.bind(this);
        }
      }
    })
   
  }


}

  
  




