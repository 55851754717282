import {Input,  ChangeDetectorRef, Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalHubAccessService } from '../../access.service';
import { DocumentCategoryEditorComponent } from 'src/app/components/pages/selected-case-page/document/document-category-editor/document-category-editor.component';
import { IDocumentLimitedGrid } from 'src/app/components/pages/selected-case-page/document/document-grid/document-grid.component';

@Component({
    selector: 'app-document-category-modal',
    templateUrl: './document-category-modal.component.html',
    styleUrls: ['../modal.component.scss'],
    standalone: true
})
export class DocumentCategoryModalComponent implements OnInit {
  constructor(private change: ChangeDetectorRef, private modalAccess: ModalHubAccessService) {}

  public self!: ComponentRef<DocumentCategoryModalComponent>;
  public component!: ComponentRef<DocumentCategoryEditorComponent>


  @Input() data!: IDocumentLimitedGrid;
  @ViewChild("modalRef", {read: ViewContainerRef}) $ref!: ViewContainerRef;

  ngOnInit(): void {}

  ngAfterViewInit(): void { 
      this.$ref.clear();
      const component = this.$ref.createComponent(DocumentCategoryEditorComponent);      
      document.addEventListener("keydown", this.onKeyboardPress)
      component.instance.data = this.data;
      this.change.detectChanges();
      this.modalAccess.activeModal = component.instance
  }

  ngOnDestroy(): void {
    document.removeEventListener("keydown", this.onKeyboardPress);
    this.$ref.clear();
  }

  destroyModal() {
    this.modalAccess.closeModal(this.modalAccess.activeModal.output);       
    this.self.destroy();
  }

  onKeyboardPress = (event: KeyboardEvent) => {
    if(event.key === 'Escape') {
      this.self.destroy();
    }
  }


}
