import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DatalexClient, ICaseBE } from '@datalex-software-as/datalex-client';

@Injectable({
  providedIn: 'root'
})
export class CaseResolverService  {

  constructor(private readonly dlxClient: DatalexClient) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICaseBE> {
    const id = route.params['id'];
    return this.dlxClient.GetCase(id, true);
  }
}
