import { Input, ChangeDetectorRef, Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalHubAccessService } from '../../access.service';
import { DocumentCategoryEditorComponent } from 'src/app/components/pages/selected-case-page/document/document-category-editor/document-category-editor.component';
import { IgxGridCell } from '@infragistics/igniteui-angular';
import { CustomerComponent } from 'src/app/components/pages/customers/customer/customer.component';
import { ICustomerBE } from '@datalex-software-as/datalex-client';

@Component({
  selector: 'app-customer-modal',
  templateUrl: './customer-modal.component.html',
  styleUrls: ['../modal.component.scss']
})
export class CustomerModalComponent implements OnInit {
  constructor(private change: ChangeDetectorRef, private modalAccess: ModalHubAccessService) { }

  public self!: ComponentRef<CustomerModalComponent>;
  public component!: ComponentRef<CustomerComponent>


  @Input() data!: ICustomerBE;
  @ViewChild("modalRef", { read: ViewContainerRef }) $ref!: ViewContainerRef;

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.$ref.clear();
    const component = this.$ref.createComponent(CustomerComponent);
    document.addEventListener("keydown", this.onKeyboardPress)
    component.instance.customer = this.data;
    component.instance.self = this.self;
    this.change.detectChanges();
    this.modalAccess.activeModal = component.instance
  }

  ngOnDestroy(): void {
    document.removeEventListener("keydown", this.onKeyboardPress);
    this.$ref.clear();
  }

  destroyModal() {
    this.modalAccess.closeModal(this.modalAccess.activeModal.output);
    this.self.destroy();
  }

  onKeyboardPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.self.destroy();
    }
  }


}
