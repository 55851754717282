export const isChanged = (oldKey: any, newKey: any) => {
    if (typeof oldKey !== typeof newKey) {
        return false //!(oldKey === newKey);
    }

    if (typeof oldKey === "object" && typeof newKey === "object") {
        const oldValue = JSON.stringify(oldKey);
        const newValue = JSON.stringify(newKey);
        return !(oldValue === newValue)
    }

    if (typeof (oldKey) === 'string' && typeof (newKey) === 'string' && newKey.includes("Id")) {
        return !((oldKey as string).toUpperCase() === (newKey as string).toUpperCase());
    }

    return !(oldKey === newKey)
}
