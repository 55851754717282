import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApplicationInsightsService, ITrackRequestTelemetry } from '../services/application-insights.service';

@Injectable()
export class DatalexApiInterceptor implements HttpInterceptor {
  private allowedDomain = 'datalexsoftware.no';
  constructor(private appInsights: ApplicationInsightsService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const startTime = performance.now(); // Start tracking time
    const parsedUrl = new URL(req.url);
    const hostnameAndPort = `${parsedUrl.hostname}:${parsedUrl.port}`;

    const isApiRequest = req.url.includes(this.allowedDomain);
    if (!isApiRequest) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const duration = Math.floor(performance.now() - startTime);
            const params: ITrackRequestTelemetry = {
              method: req.method,
              endpoint: req.url.split('/').slice(-1)[0],
              url: req.urlWithParams,
              duration: duration,
              responseCode: event.status,
              success: true,
              server: hostnameAndPort
            };

            this.appInsights.trackRequest(params);
          }
        },
        error: (error: HttpErrorResponse) => {
          const duration = Math.floor(performance.now() - startTime);
          const params: ITrackRequestTelemetry = {
            method: req.method,
            endpoint: req.url.split('/').slice(-1)[0],
            url: req.urlWithParams,
            duration: duration,
            responseCode: error.status,
            success: false,
            server: hostnameAndPort
          };

          this.appInsights.trackRequest(params);
        }
      })
    );
  }
}