<ng-container [ngTemplateOutlet]="valueDisplay" [ngTemplateOutletContext]="{cell:cell}">
</ng-container>

<ng-template #valueDisplay>
    <div class="grid-select" #select (click)="cellClick(filter, $event)" [igxToggleAction]="dropdown" [igxDropDownItemNavigation]="dropdown">
        {{cell.value ?? " - "}}
    </div>

    <igx-drop-down #dropdown (selectionChanging)="selectionChanging($event, cell)">
        <div class="drop-down-virtual-wrapper">
            <input #filter id="filter" type="text" (input)="filterSelect(filter.value, $event)" />
            <igx-drop-down-item
                *igxFor="let option of options; index as index; scrollOrientation: 'vertical'; containerSize: itemsMaxHeight; itemSize: itemHeight;"
                [value]="option" role="option"
                [index]="index">
                {{ option.Name }}
            </igx-drop-down-item>
        </div>
    </igx-drop-down>
    
</ng-template>





<!--
<ng-template #valueDisplay let-cell="cell">


    <div #valueDisplay [igxToggleAction]="dropdown" [igxDropDownItemNavigation]="dropdown" [igxToggleOutlet]="outlet" [overlaySettings]="overlaySettings">
        <div class="grid-select" #select (click)="cellClick(filter, $event)">
            {{cell.value ?? " - "}}
        </div>
        <div igxOverlayOutlet #outlet="overlay-outlet"></div>
    </div>

    <igx-drop-down #dropdown>
        <div class="dropdown">
            <input #filter id="filter" type="text" (input)="filterSelect(filter.value, $event)" />
            <div class="dropdown_item_wrapper">
                
                <div class="dropdown_item" *ngFor="let item of options" (click)="optionSelection(item, cell)">
                    {{ item[fieldName] }}
                </div>
            </div>
        </div>
    </igx-drop-down>
</ng-template>

-->

<!--

    <ng-template #valueDisplay let-cell="cell">
        <div class="grid-select" #select (click)="cellClick(filter, $event)">
            {{cell.value ?? "  -  "}}
        </div>
        <div class="dropdown" [ngClass]="isActive ? 'show' : 'hide'">
            <input #filter id="filter" type="text" (input)="filterSelect(filter.value, $event)"/>
            <div class="dropdown_item_wrapper">
                <div class="dropdown_item" *ngFor="let item of options" (click)="optionSelection(item, cell)">
                    {{ item[fieldName] }}
                </div>
            </div>
        </div>
    </ng-template>
    
-->


<!--



<ng-container 
    [ngTemplateOutlet]="isActive ? gridSelect : valueDisplay" 
    [ngTemplateOutletContext]="{cell:cell}">
</ng-container>


<ng-template #gridSelect let-cell="cell">
    <select [id]="id" class="grid-select" (change)="change($event.currentTarget, cell); isActive = !isActive" (focus)="gridSelectFocus(cell)" (blur)="gridSelectBlur(cell)" [ngModel]="null">
      <option class="grid-option" *ngFor="let item of datasource" [ngValue]="item"> {{ item[fieldName] }} </option>
    </select>
  </ng-template>
  



  -->