import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import {
  IgxGridModule,
  IgxRippleModule,
  IgxInputGroupModule,
  IgxDatePickerModule,
  IgxTabsModule,
  IgxAutocompleteModule,
  IgxDropDownModule,
  IgxSelectModule,
  IgxIconModule,
  IgxButtonModule,
  IgxCheckboxModule,
  IgxCardModule,
  IgxSimpleComboModule,
  IgxComboModule,
  IgxNavigationDrawerModule,
  IgxNavbarModule,
  IgxAccordionModule,
  IgxStepperModule,
  IgxOverlayService,
  IgxToggleModule,
  IgxButtonGroupModule,
  IgxMaskModule, IgxTooltipModule,
  IgxBadgeModule,
  IgxProgressBarModule,
  IgxActionStripModule,
  IgxListModule,
  IgxSnackbarModule,
  IgxToastModule,
  IgxDateTimeEditorModule,
  IgxDialogModule,
  IgxBannerModule
} from "@infragistics/igniteui-angular";
import { ContactFilterProvider } from './util/ContactFilters';
import { CookieProvider } from './util/Cookies';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/pages/login/login.component';
import { MyCasesComponent } from './components/pages/my-cases/my-cases.component';
import { SelectedCasePageComponent } from './components/pages/selected-case-page/selected-case-page.component';
import { SelectedCaseComponent } from './components/pages/selected-case-page/selected-case/selected-case.component';
import { CaseRolesComponent } from './components/pages/selected-case-page/case-roles/case-roles.component';
import { KeyFinFiguresComponent } from './components/pages/selected-case-page/key-fin-figures/key-fin-figures.component';
import { LabelInputComponent } from './components/UI/controls/label-input/label-input.component';
import { CommonModule } from '@angular/common';
import { HourRegistrationFilterProvider } from './util/HourRegistrationFilters';
import { TitleComponent } from './components/UI/title/title.component';
import { HeadingComponent } from './components/UI/heading/heading.component';
import { FindCaseComponent } from './components/pages/find-case/find-case.component';
import { ToggleSwitchComponent } from './components/UI/controls/toggle-switch/toggle-switch.component';
import { RoleFilterProvider } from './util/RoleFilters';
import { EventEmitterService } from './services/event-emitter.service';
import { MenuComponent } from './components/UI/menu/menu.component';
import { MenuItemsComponent } from './components/UI/menu-items/menu-items.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormErrorMessageComponent } from './components/UI/form-error-message/form-error-message.component';
import { KeydownStopPropagationDirective } from './directives/keydown-stop-propagation.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TestPageComponent } from './components/pages/test-page/test-page.component';
import { DlxTextareaComponent } from './components/UI/dlx-textarea/dlx-textarea.component';
import { ActivityLogOverlayComponent } from './components/UI/activity-log-overlay/activity-log-overlay.component';
import { ActivityLogOverlayItemComponent } from './components/UI/activity-log-overlay/activity-log-overlay-item/activity-log-overlay-item.component';
import { DocumentComponent } from './components/pages/selected-case-page/document/document.component';
import { DocumentGridComponent } from './components/pages/selected-case-page/document/document-grid/document-grid.component';
import { DndDirective } from './directives/dnd.directive';
import { CheckoutListItemComponent } from './components/UI/document-checkout/checkout-list-item/checkout-list-item.component';
import { CheckoutListComponent } from './components/UI/document-checkout/checkout-list/checkout-list.component';
import { DocumentCheckoutComponent } from './components/UI/document-checkout/document-checkout.component';
import { InformationOverlayComponent } from './components/UI/info-overlay/information-overlay.component';
import { ContextMenuComponent } from './components/UI/context-menu/context-menu.component';
import { ContextmenuComponent } from './components/UI/contextmenu/contextmenu.component';
import { ContextmenuItemComponent } from './components/UI/contextmenu/contextmenu-item/contextmenu-item.component';
import { DocumentDetailEditorComponent } from './components/pages/selected-case-page/document/document-detail-editor/document-detail-editor.component';
import { ModalHubComponent } from './components/UI/modal-hub/modal-hub.component';
import { UploadDocumentGridComponent } from './components/pages/selected-case-page/document/upload-document-grid/upload-document-grid.component';
import { DocumentDetailsModalComponent } from './components/UI/modal-hub/modals/document-details-modal/document-details-modal.component';
import { HourRegistrationModalComponent } from './components/UI/modal-hub/modals/hour-registration-modal/hour-registration-modal.component';
import { DocumentUploadModalComponent } from './components/UI/modal-hub/modals/document-upload-modal/document-upload-modal.component';
import { DlxDatetimeReadonlyComponent } from './components/UI/dlx-datetime-readonly/dlx-datetime-readonly.component';
import { DlxCheckboxComponent } from './components/UI/dlx-checkbox/dlx-checkbox.component';
import { NavigationBarComponent } from './components/UI/navigation-bar/navigation-bar.component';
import { PageComponentComponent } from './components/UI/page-component/page-component.component';
import { MenuTitleComponent } from './components/UI/menu-title/menu-title.component';
import { MenuMessageComponent } from './components/UI/menu-message/menu-message.component';
import { DocumentFromTemplateModalComponent } from './components/UI/modal-hub/modals/document-from-template-modal/document-from-template-modal.component';
import { DocumentFromTemplateComponent } from './components/pages/selected-case-page/document/document-from-template/document-from-template.component';
import { ButtonRowButtonComponent } from './components/UI/button-row-button/button-row-button.component';
import { ProfilePictureModalComponent } from './components/UI/modal-hub/modals/profile-picture-modal/profile-picture-modal.component';
import { ProfilePictureEditorComponent } from './components/UI/profile-picture-editor/profile-picture-editor.component';
import { ContactsComponent } from './components/pages/contacts/contacts.component';
import { DimensionsDirective } from './directives/dimensions.directive';
import { GlobalErrorHandler } from './classes/GlobalErrorHandler';
import { ContactComponent } from './components/pages/contact/contact.component';
import { DlxAddressInputComponent } from './components/UI/dlx-address-input/dlx-address-input.component';
import { BusinessDesktopContactComponent } from './components/pages/new-contact/new-contact-desktop/business-desktop-contact/business-desktop-contact.component';
import { NewContactDesktopComponent } from './components/pages/new-contact/new-contact-desktop/new-contact-desktop.component';
import { PersonalDesktopContactComponent } from './components/pages/new-contact/new-contact-desktop/personal-desktop-contact/personal-desktop-contact.component';
import { BusinessContactComponent } from './components/pages/new-contact/new-contact-mobile/business-contact/business-contact.component';
import { NewContactMobileComponent } from './components/pages/new-contact/new-contact-mobile/new-contact-mobile.component';
import { PersonalContactComponent } from './components/pages/new-contact/new-contact-mobile/personal-contact/personal-contact.component';
import { NewContactComponent } from './components/pages/new-contact/new-contact.component';
import { ContactInformationComponent } from './components/pages/contact/contact-information/contact-information.component';
import { ContactSummaryComponent } from './components/pages/contact/contact-summary/contact-summary.component';
import { ContactCasesComponent } from './components/pages/contact/contact-cases/contact-cases.component';
import { ContactContactinfoComponent } from './components/pages/contact/contact-contactinfo/contact-contactinfo.component';
import { ContactSearchModalComponent } from './components/UI/modal-hub/modals/contact-search-modal/contact-search-modal.component';
import { ModalComponent } from './components/UI/modal-hub/modal/modal.component';
import { ReceiptScannerComponent } from './components/UI/receipt-scanner/receipt-scanner.component';
import { BreadcrumbComponent } from './components/UI/breadcrumb/breadcrumb.component';
import { ZipCodeSearchComponent } from './components/UI/zip-code-search/zip-code-search.component';
import { NoPageComponent } from './components/pages/no-page/no-page.component';
import { TwoOnePanelComponent } from './components/UI/layout/two-one-panel/two-one-panel.component';
import { OneOnePanelComponent } from './components/UI/layout/one-one-panel/one-one-panel.component';
import { OnePanelComponent } from './components/UI/layout/one-panel/one-panel.component';
import { GridSelectComponent } from './components/UI/grid-select/grid-select.component';
import { DocumentCategoryModalComponent } from './components/UI/modal-hub/modals/document-category-modal/document-category-modal.component';
import { DocumentCategoryEditorComponent } from './components/pages/selected-case-page/document/document-category-editor/document-category-editor.component';
import { ModalHubAccessService } from './components/UI/modal-hub/access.service';
import { IgxSelectWrapperComponent } from './components/UI/igx-select-wrapper/igx-select-wrapper.component';
import { GridFilterInputComponent } from './components/UI/grid-filter-input/grid-filter-input.component';
import { CustomersComponent } from './components/pages/customers/customers.component';
import { DocumentTypesComponent } from './components/pages/document-types/document-types.component';
import { CustomerComponent } from './components/pages/customers/customer/customer.component';
import { DialogModalComponent } from './components/dialog-modal/dialog-modal.component';
import { CustomerMessagesComponent } from './components/pages/customers/customer/customer-messages/customer-messages.component';
import { CustomerUsersComponent } from './components/pages/customers/customer/customer-users/customer-users.component';
import { CustomerStatesticInfoComponent } from './components/pages/customers/customer/customer-statestic-info/customer-statestic-info.component';
import { ChargingComponent } from './components/pages/selected-case-page/charging/charging.component';
import { TabsComponent } from './components/UI/tabs/tabs.component';
import { CostsComponent } from './components/pages/selected-case-page/charging/costs/costs.component';
import { DemoCompComponent } from './components/UI/demo-comp/demo-comp.component';
import { AutoFocus } from './directives/auto-focus.directive';
import { HoursRegistrationComponent } from './components/pages/selected-case-page/charging/hours-registration/hours-registration.component';
import { MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { FirstLetterPipe } from "./util/pipes/first-letter.pipe";
import { InitialsPipe } from "./util/pipes/initials.pipe";
import { ClearGridFiltersDirective } from './directives/clear-grid-filters.directive';
import { NorwegianNumberFormatPipe } from './util/pipes/norwegian-number-format.pipe';
import { GridItemCountComponent } from './components/UI/grid-item-count/grid-item-count.component';
import { GridFooterCollectionComponent } from './components/UI/grid-footer-collection/grid-footer-collection.component';
import { BirthDayNumberValidatorDirective } from './directives/birth-day-number-validator.directive';
import { EmailValidatorDirective } from './directives/emailvalidator.directive';
import { ToolbarComponent } from './components/UI/toolbar/toolbar.component';
import { GridCountDirective } from './directives/grid-count.directive';
import { OTPInputComponent } from './components/UI/otpinput/otpinput.component';
import './locale.config';
import { DatalexApiInterceptor } from './interceptors/ApiInterceptor';
import { ApplicationInsightsService } from './services/application-insights.service';



@NgModule({
  declarations: [AppComponent],
  providers: [
    CookieProvider,
    ContactFilterProvider,
    HourRegistrationFilterProvider,
    RoleFilterProvider,
    EventEmitterService,
    IgxOverlayService,
    ModalHubAccessService,
    ApplicationInsightsService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LOCALE_ID, useValue: 'no' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DatalexApiInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IgxNavigationDrawerModule,
    IgxNavbarModule,
    HammerModule,
    IgxGridModule,
    IgxRippleModule,
    IgxInputGroupModule,
    IgxDatePickerModule,
    IgxTabsModule,
    IgxAutocompleteModule,
    IgxDropDownModule,
    IgxSelectModule,
    IgxIconModule,
    IgxButtonModule,
    IgxButtonGroupModule,
    IgxCheckboxModule,
    IgxCardModule,
    IgxComboModule,
    IgxAccordionModule,
    IgxStepperModule,
    IgxToggleModule,
    IgxMaskModule,
    DragDropModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    IgxTooltipModule,
    IgxSimpleComboModule,
    IgxBadgeModule,
    IgxProgressBarModule,
    IgxActionStripModule,
    IgxListModule,
    IgxRippleModule,
    IgxSnackbarModule,
    IgxToastModule,
    IgxDateTimeEditorModule,
    IgxDialogModule,
    FirstLetterPipe,
    InitialsPipe,
    IgxBannerModule,
    GridItemCountComponent,
    GridFooterCollectionComponent,
    MsalModule.forRoot(MSALInstanceFactory(), MSALGuardConfigFactory(), MSALInterceptorConfigFactory()),
    LoginComponent,
    MyCasesComponent,
    SelectedCaseComponent,
    SelectedCasePageComponent,
    LabelInputComponent,
    KeyFinFiguresComponent,
    CaseRolesComponent,
    TitleComponent,
    HeadingComponent,
    FindCaseComponent,
    ToggleSwitchComponent,
    MenuComponent,
    MenuItemsComponent,
    FormErrorMessageComponent,
    DlxTextareaComponent,
    TestPageComponent,
    KeydownStopPropagationDirective,
    ActivityLogOverlayComponent,
    ActivityLogOverlayItemComponent,
    DocumentComponent,
    DndDirective,
    DocumentCheckoutComponent,
    CheckoutListComponent,
    CheckoutListItemComponent,
    InformationOverlayComponent,
    ContextMenuComponent,
    ContextmenuComponent,
    ContextmenuItemComponent,
    ModalHubComponent,
    DocumentDetailEditorComponent,
    HourRegistrationModalComponent,
    DocumentUploadModalComponent,
    UploadDocumentGridComponent,
    DocumentDetailsModalComponent,
    DlxDatetimeReadonlyComponent,
    DlxCheckboxComponent,
    NavigationBarComponent,
    PageComponentComponent,
    MenuTitleComponent,
    MenuMessageComponent,
    DocumentFromTemplateModalComponent,
    DocumentFromTemplateComponent,
    ButtonRowButtonComponent,
    ProfilePictureModalComponent,
    ProfilePictureEditorComponent,
    ContactsComponent,
    DimensionsDirective,
    NewContactComponent,
    NewContactMobileComponent,
    NewContactDesktopComponent,
    ContactComponent,
    PersonalContactComponent,
    BusinessContactComponent,
    PersonalDesktopContactComponent,
    BusinessDesktopContactComponent,
    DlxAddressInputComponent,
    ContactInformationComponent,
    ContactSummaryComponent,
    ContactCasesComponent,
    ContactContactinfoComponent,
    ContactSearchModalComponent,
    ModalComponent,
    ReceiptScannerComponent,
    BreadcrumbComponent,
    GridFilterInputComponent,
    ZipCodeSearchComponent,
    NoPageComponent,
    TwoOnePanelComponent,
    OneOnePanelComponent,
    OnePanelComponent,
    GridSelectComponent,
    DocumentCategoryModalComponent,
    DocumentCategoryEditorComponent,
    DocumentGridComponent,
    IgxSelectWrapperComponent,
    CustomersComponent,
    DocumentTypesComponent,
    CustomerComponent,
    DialogModalComponent,
    CustomerMessagesComponent,
    CustomerUsersComponent,
    CustomerStatesticInfoComponent,
    ChargingComponent,
    TabsComponent,
    CostsComponent,
    AutoFocus,
    HoursRegistrationComponent,
    DemoCompComponent,
    ClearGridFiltersDirective,
    NorwegianNumberFormatPipe,
    BirthDayNumberValidatorDirective,
    EmailValidatorDirective,
    GridCountDirective,
    ToolbarComponent,
    OTPInputComponent
  ]
})
export class AppModule {
}



const msal = () => {
  return
}

export function MSALInstanceFactory(): PublicClientApplication {
  // Placeholder configuration - replace with actual minimal config
  return new PublicClientApplication({
    auth: {
      clientId: 'dummy', // Replace with actual minimal placeholder values
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: '/'
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", [".default"]);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['.default']
    },
    loginFailedRoute: "/"
  };
}