import { EventEmitter } from '@angular/core';
import { IDocumentTypeBE, } from "../../../node_modules/@datalex-software-as/datalex-client/lib/Client/types";

import { isChanged } from "../util/Compare";


export class DocumentTypeBE {

    private _Id!: string;
    private _Name!: string;
    private _Order!: number;
    private _DeleteAllowed!: boolean;
    private _IsTemplateType!: boolean;
    private _Timestamp!: number[];
    private _IsNew!: boolean;
    private _IsDeleted!: boolean;
    private _SessionId?: string;
    private _Origin!: IDocumentTypeBE;

    public isChanged: boolean | undefined = false;
    public changed = new EventEmitter<IDocumentTypeBE>()

    private set Id(val: string) { this._Id = val };
    private set Name(val: string) { this._Name = val };
    private set Order(val: number) { this._Order = val };
    private set DeleteAllowed(val: boolean) { this._DeleteAllowed = val };
    private set IsTemplateType(val: boolean) { this._IsTemplateType = val };
    private set Timestamp(val: number[]) { this._Timestamp = val };
    private set Origin(val: IDocumentTypeBE) { this._Origin = val };
    private set IsNew(val: boolean) { this._IsNew = val };
    private set IsDeleted(val: boolean) { this._IsDeleted = val };
    private set SessionId(val: string | undefined) { this._SessionId = val };

    public get Id() { return this._Id };
    public get Name() { return this._Name };
    public get Order() { return this._Order };
    public get DeleteAllowed() { return this._DeleteAllowed };
    public get IsTemplateType() { return this._IsTemplateType };
    public get Timestamp() { return this._Timestamp };
    private get Origin() { return this._Origin };
    public get IsNew() { return this._IsNew };
    public get IsDeleted() { return this._IsDeleted };
    public get SessionId() { return this._SessionId };


    public setId(val: string) {
        this.Id = val;
        this.isChanged = isChanged(this.Origin.Id, this.Id);
        if (this.isChanged) {
            this.changed.emit(this.Export());
            //this.changed.emit(null);
        }
        return this;
    }

    public setName(val: string) {
        this.Name = val;
        this.isChanged = isChanged(this.Origin.Name, this.Name);
        if (this.isChanged) {
            this.changed.emit(this.Export());
            //this.changed.emit(null);
        }
        return this;
    }

    public setOrder(val: number) {
        this.Order = val;
        this.isChanged = isChanged(this.Origin.Order, this.Order);
        if (this.isChanged) {
            this.changed.emit(this.Export());
            //this.changed.emit(null);
        }
        return this;
    }

    public setDeleteAllowed(val: boolean) {
        this.DeleteAllowed = val;
        this.isChanged = isChanged(this.Origin.DeleteAllowed, this.DeleteAllowed);
        if (this.isChanged) {
            this.changed.emit(this.Export());
            //this.changed.emit(null);
        }
        return this;
    }

    public setIsTemplateType(val: boolean) {
        this.IsTemplateType = val;
        this.isChanged = isChanged(this.Origin.IsTemplateType, this.IsTemplateType);
        if (this.isChanged) {
            this.changed.emit(this.Export());
            //this.changed.emit(null);
        }
        return this;
    }

    public setTimestamp(val: number[]) {
        this.Timestamp = val;
        this.isChanged = isChanged(this.Origin.Timestamp, this.Timestamp);
        if (this.isChanged) {
            this.changed.emit(this.Export());
            //this.changed.emit(null);
        }
        return this;
    }


    constructor(props?: IDocumentTypeBE) {
        if (props) {
            this.Id = props.Id;
            this.Name = props.Name;
            this.Order = props.Order;
            this.DeleteAllowed = props.DeleteAllowed;
            this.IsTemplateType = props.IsTemplateType;
            this.Timestamp = props.Timestamp;
            this.IsNew = props.IsNew;
            this.IsDeleted = props.IsDeleted;
        }
    }


    Export(origin: boolean = false): IDocumentTypeBE {
        if (origin) return this.Origin;
        return {
            Id: this.Id,
            Name: this.Name,
            Order: this.Order,
            DeleteAllowed: this.DeleteAllowed,
            IsTemplateType: this.IsTemplateType,
            Timestamp: this.Timestamp,
            IsNew: this.IsNew,
            IsDeleted: this.IsDeleted
        }
    }

}