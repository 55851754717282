import { Component, Input, OnInit } from '@angular/core';
import { CaseBE } from 'src/app/classes/CaseBE';
import { CostsComponent } from './costs/costs.component';
import { IgxTabsComponent, IgxTabItemComponent, IgxTabHeaderComponent, IgxTabHeaderLabelDirective, IgxTabContentComponent } from '@infragistics/igniteui-angular';

@Component({
    selector: 'app-charging',
    templateUrl: './charging.component.html',
    styleUrls: ['./charging.component.scss'],
    standalone: true,
    imports: [IgxTabsComponent, IgxTabItemComponent, IgxTabHeaderComponent, IgxTabHeaderLabelDirective, IgxTabContentComponent, CostsComponent]
})
export class ChargingComponent implements OnInit {
  @Input() data!: CaseBE;
  tabs: string[] = ['Timer', 'Omkostninger'];
  activatedTabIndex: number = 0;
  constructor() { }
  trigger = 0;

  ngOnInit(): void {

  }
  tabChange(tabIndex: number) {
    this.activatedTabIndex = tabIndex;
  }

}
