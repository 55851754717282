import { Component, OnInit, ViewChild } from '@angular/core';
import { ConnectedPositioningStrategy, HorizontalAlignment, VerticalAlignment, OverlaySettings, IgxButtonDirective, IgxToggleActionDirective, IgxBadgeComponent, IgxDropDownComponent, IgxListComponent, IgxListItemComponent, IgxRippleDirective, IgxListLineTitleDirective, IgxCircularProgressBarComponent } from '@infragistics/igniteui-angular';
import { DatalexClient, IDocumentSharePointExtendedBE } from '@datalex-software-as/datalex-client';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { DocumentCheckoutService } from './document-checkout.service';
import { ActivityLogService } from '../activity-log-overlay/activity-log.service';
import Activity from 'src/app/classes/Activity/Actvity';
import { EventEmitterService, IForcedEventTypeEnum } from 'src/app/services/event-emitter.service';
import newActivity from 'src/app/classes/Activity/Actvity';
import { MicrosoftGraphService } from 'src/app/services/graph.service';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import CheckInErrorMessage from 'src/app/util/CheckInErrorMessage';
import { NgFor, DatePipe } from '@angular/common';
import { DatalexMsalService } from 'src/app/services/datalex-msal.service';
import { Router } from '@angular/router';




@Component({
    selector: 'app-document-checkout',
    templateUrl: './document-checkout.component.html',
    styleUrls: ['./document-checkout.component.scss'],
    standalone: true,
    imports: [IgxButtonDirective, IgxToggleActionDirective, IgxBadgeComponent, IgxDropDownComponent, IgxListComponent, IgxListItemComponent, IgxRippleDirective, NgFor, IgxListLineTitleDirective, IgxCircularProgressBarComponent, DatePipe]
})
export class DocumentCheckoutComponent implements OnInit {


  public overlaySettings: OverlaySettings = {
    modal: false,
    positionStrategy: new ConnectedPositioningStrategy({
      horizontalStartPoint: HorizontalAlignment.Center,
      horizontalDirection: HorizontalAlignment.Center,
      verticalStartPoint: VerticalAlignment.Bottom,
      closeAnimation: undefined
    })
  };

  constructor(public dcs: DocumentCheckoutService, private dlxClient: DatalexClient, private sys: SystemCacheService, private als: ActivityLogService,
    private eventEmitterService: EventEmitterService, private graph: MicrosoftGraphService, public screen: ScreenDimensionService, private dlxMsal: DatalexMsalService, private router: Router) {
  }

  ngOnInit(): void {
    try {
      this.dcs.getCheckedOutDocuments(this.sys.loggedInUser.Id)
    } catch (error) {
      this.dcs.getCheckedOutDocuments(this.sys.loggedInUser.Id)
    }

    this.dcs.spinners.fill(false);
  }

  removeRow(doc: IDocumentSharePointExtendedBE, index: number): void {
    if (!doc) return;

    this.dcs.spinners[index] = true;
    this.dlxMsal.useToken(this.router.url).subscribe({
      next: (token) => {
        this.checkInDocument(doc, token).then(() => {
          this.dcs.getCheckedOutDocuments(this.sys.loggedInUser.Id);
          this.eventEmitterService.sendForceUpdate({ type: IForcedEventTypeEnum.DocumentCheckedIn, id: doc.DocumentId })
        }).catch(() => {
            this.dcs.spinners[index] = false;
        }).finally(() => {
            setTimeout(() => {
              this.dcs.spinners[index] = false;;
            }, 500);
        });
      }
    });

  }

  checkInDocument(doc: IDocumentSharePointExtendedBE, accessToken: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const handle = this.dcs.popupHandleLookup(doc.DocumentId);
      if (handle && handle.window.closed === false) {
        this.als.appendActivity(newActivity({
          message: "Dokumentet er fortsatt åpent. Vennligst lukk vinduet før du sjekker inn.",
          type: "warning",
          userDismiss: true,
          timestamp: new Date()
        }));

        reject(new Error("The document window is still open. Please close the window before checking in."));
        return;
      }
      this.dlxClient.CheckInDocumentSharePoint(doc.DocumentId, accessToken).subscribe({
        next: () => {
          this.dcs.removePopupHandle(doc.DocumentId);
          resolve();
        },
        error: (er) => {
          let error = CheckInErrorMessage(er.error);
          if (error) {

            if (error.type === "locked") {
            
              this.forceLockRemove(doc, this.dcs.docs.indexOf(doc));
            }

            const activity = newActivity({
              message: error.message(doc.Title),
              type: "failure",
              timestamp: new Date()
            })
            this.als.appendActivity(activity)
          }
          reject(er);
        }
      });
    });
  }

  removeAll(): void {
    if (this.dcs.docs.length === 0) return;
    this.dcs.spinners.fill(true);

    const checkInPromises: Promise<void>[] = [];
    this.dlxMsal.useToken(this.router.url).subscribe({
      next: (token) => {
        this.dcs.docs.forEach((doc) => {
          const checkInPromise = this.checkInDocument(doc, token)
            .then(() => {
              this.eventEmitterService.sendForceUpdate({ type: IForcedEventTypeEnum.DocumentCheckedIn, id: doc.DocumentId })
            })
            .catch((error) => {
              
            })
    
          checkInPromises.push(checkInPromise);
        });
      }
    })


    Promise.allSettled(checkInPromises)
      .then(() => {
        this.dcs.getCheckedOutDocuments(this.sys.loggedInUser.Id);
        setTimeout(() => {
          this.dcs.spinners.fill(false);
        }, 500);
        // this.eventEmitterService.sendForceUpdate({ type: IForcedEventTypeEnum.DocumentsUpdated });
      })

  }

  forceLockRemove(doc: IDocumentSharePointExtendedBE, index: number) {
    const handle = this.dcs.popupHandleLookup(doc.DocumentId);
    if (handle && handle.window.closed === false) {
      
      return
    }
    if (handle && handle.parentRefrence) {
      const { Id, DriveId } = handle.parentRefrence;
      // this.graph.graphClient.api(`/drives/${DriveId}/items/${doc.SharePointId}}/checkin`).post({comment: ""}).then((()=> {
      //   this.removeRow(doc, index);
      // }));
      // this.graph.graphClient.api(`/drives/${DriveId}/items/${doc.SharePointId}/checkout`).post(null).then(() => {
      // })
    }
  }

  deleteViewOnlyFile() {
    //Savner en metode på RESTAPI for å slette temp filer i SharePoint som ikke skal lagres
    this.dlxClient.DeleteDocumentSharePoint("", "").subscribe({
      next: () => { },
      error: (err) => { }
    })
  }
}
