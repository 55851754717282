import { Injectable } from '@angular/core';
import { SystemCacheService } from './services/system-cache.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthTestService {

  constructor(private sys: SystemCacheService) { }

  logedAsMaster(): boolean {
    if (this.sys.isMaster) {
      return true;
    } else {
      return false
    }

  }
}
