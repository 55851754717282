import { ApplicationRef, Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-no-page',
    templateUrl: './no-page.component.html',
    styleUrls: ['./no-page.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class NoPageComponent implements OnInit {

  constructor(private appRef: ApplicationRef) { }

  ngOnInit(): void {
    screen.orientation.addEventListener("change", (event) => {
      this.appRef.tick();
    });
  }

}
