@if(activity.userDismiss) {
  <div class="overlay-log-item-wrapper">
    <div class="item-text">
      <igx-icon> {{ iconName }} </igx-icon>
      <span>{{ activityText }}</span>
    </div>
    <i class="material-icons icon-default" (click)="dismiss()">close</i>
  </div>
}
@else if(activity.type !== 'info'){
<div class="overlay-log-item-wrapper with_fadeaway">
  <div class="item-text">
    <igx-icon> {{ iconName }} </igx-icon>
    <span>{{ activityText }}</span>
  </div>
  <i class="material-icons icon-default" (click)="dismiss()">close</i>
</div>

} @else {
<div class="overlay-log-item-wrapper">
  <div class="item-text">
    <igx-icon> {{ iconName }} </igx-icon>
    <span>{{ activityText }}</span>
  </div>
  <i class="material-icons icon-default" (click)="dismiss()">close</i>
</div>

}

@if( activity.type === 'info'){
<igx-linear-bar [value]="100" class="indeterminate" [indeterminate]="true" [striped]="true"></igx-linear-bar>
}
@if( activity.type === 'download'){
<igx-linear-bar [value]="activity.progress!" [max]="activity.max!" class="indeterminate" [indeterminate]="false" [striped]="true"></igx-linear-bar>
}
