import { AfterViewChecked, Component, ComponentRef, ElementRef, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatalexClient, ICaseSearchResultBE, ICustomerBE, IUserInfoBE, IUserLimitedBE, IUtilTraceMessagesBE } from '@datalex-software-as/datalex-client';
import { IGridCellEventArgs, IgxInputGroupTheme, IgxInputGroupComponent, IgxLabelDirective, IgxInputDirective, IgxTooltipTargetDirective, IgxSuffixDirective, IgxIconComponent, IgxTooltipDirective, IgxTabsComponent, IgxTabItemComponent, IgxTabHeaderComponent, IgxTabHeaderLabelDirective, IgxTabContentComponent, IgxButtonDirective, IgxRippleDirective, IgxGridComponent, IgxColumnComponent, IgxFilterCellTemplateDirective } from '@infragistics/igniteui-angular';
import { CustomerModalComponent } from 'src/app/components/UI/modal-hub/modals/customer-modal/customer-modal.component';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { toLocalIsoString } from 'src/app/util/DatalexDateTime';
import { GridFilterInputComponent } from '../../../UI/grid-filter-input/grid-filter-input.component';
import { TitleComponent } from '../../../UI/title/title.component';
import { NgIf, NgFor } from '@angular/common';
import { CustomerStatesticInfoComponent } from './customer-statestic-info/customer-statestic-info.component';
import { CustomerUsersComponent } from './customer-users/customer-users.component';
import { CustomerMessagesComponent } from './customer-messages/customer-messages.component';
import { FormsModule } from '@angular/forms';



interface IAdvSearch {
  nameInitials: string | null
  titleDesc: string | null
  sasb: string | null
  active: boolean
  restricted: boolean
  closed: boolean
  internal: boolean
}


@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss'],
    standalone: true,
    imports: [IgxInputGroupComponent, IgxLabelDirective, FormsModule, IgxInputDirective, IgxTooltipTargetDirective, IgxSuffixDirective, IgxIconComponent, IgxTooltipDirective, IgxTabsComponent, IgxTabItemComponent, IgxTabHeaderComponent, IgxTabHeaderLabelDirective, IgxTabContentComponent, CustomerMessagesComponent, CustomerUsersComponent, CustomerStatesticInfoComponent, NgIf, TitleComponent, NgFor, IgxButtonDirective, IgxRippleDirective, IgxGridComponent, IgxColumnComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent]
})
export class CustomerComponent implements AfterViewChecked {

  @ViewChild('messagePopup') messagePopup!: ElementRef<HTMLDivElement>


  ngAfterViewChecked() {
    if (this.selectedMessage) {
      this.messagePopup.nativeElement.hidden = false;
    }
  }

  _name!: string;
  get name() {
    return this._name;
  }

  set name(val: string) {
    this._name = val;
    this.editedCustomer.Name = val;
    this.compare(this.customer, this.editedCustomer)
  }

  _maxUsers!: number | null;
  get maxUsers() {
    return this._maxUsers;
  }

  set maxUsers(val: number | null) {
    if (val) {
      this._maxUsers = val;
      this.editedCustomer.MaxUsers = val;
      this.compare(this.customer, this.editedCustomer);
    }
  }

  _endDate!: string | null;
  get endDate() {
    return this._endDate;
  }

  set endDate(val: string | null) {
    if (val) {
      this._endDate = this.formatDate2(val, false);

      this.editedCustomer.EndDate = toLocalIsoString(val);
      this.compare(this.customer, this.editedCustomer)
    }
  }

  _customerCase!: string;
  get customerCase() {
    return this._customerCase;
  }

  set customerCase(val: string | null) {

    if (val) {
      this._customerCase = val;
    }
  }

  _shortName!: string | null;
  get shortName() {
    return this._shortName;
  }

  set shortName(val: string | null) {
    if (val) {
      this._shortName = val;
      this.editedCustomer.ShortName = val;
      this.compare(this.customer, this.editedCustomer);
    }
  }

  _webAppURL!: string | null;

  get webAppURL() {
    return this._webAppURL;
  }

  set webAppURL(val: string | null) {
    if (val) {
      this._webAppURL = val;
      this.editedCustomer.WebAppURL = val;
      this.compare(this.customer, this.editedCustomer);
    }

  }


  customer!: ICustomerBE;
  editedCustomer!: ICustomerBE;
  customerMessages: IUtilTraceMessagesBE[] = [];
  messageDetails!: IUtilTraceMessagesBE[];
  customerUsers!: IUserInfoBE[];
  customerStatistic!: ICustomerBE;
  datalexServiceURL = "";
  selectedMessage!: IUtilTraceMessagesBE | null;
  searchedCases!: ICaseSearchResultBE[] | null;

  dummyOption = {
    Id: '00000-0000-00000-00000',
    ContactId: '00000-0000-00000-00000',
    Username: "",
    Number: 0,
    Shortform: "",
    HourlyRate: null,
    HourlyCost: null,
    ContactName: "Velg en medarbeider",
    GroupName: "",
    EmployedFrom: "",
    EmployedTo: null,
    AccountsName: "",
    Title: "",
    LastLogin: null,
    LastLogout: null,
    UserAdministration: null
  }

  saSbDataSource = [this.dummyOption, ...this.sys.allEmployees];

  lastActivation!: string;
  isHidden = true;

  canOpenCase = false;

  escListener!: any;

  updated: boolean = false;


  // adv search

  adv: IAdvSearch = {
    nameInitials: null,
    titleDesc: null,
    sasb: '00000-0000-00000-00000',
    active: true,
    restricted: true,
    closed: false,
    internal: false
  }
  selected!: any;
  //
  self!: ComponentRef<CustomerModalComponent>;


  theme: IgxInputGroupTheme = "bootstrap"

  constructor(private dlxClient: DatalexClient, private router: Router, public sys: SystemCacheService) {

  }


  ngOnInit() {

    this.editedCustomer = JSON.parse(JSON.stringify(this.customer));
  

    if (this.customer.CustomerId !== null) {
      this.getCustomer(this.customer)
    }
    if (this.customer.LastActivationDate !== null) {
      this.lastActivation = this.formatDate2(this.customer.LastActivationDate)
    }
  }

  getCustomer(customer: ICustomerBE): void {

    this.populateCustomer(this.customer)

    if (!customer.CustomerId) return
    this.dlxClient.GetUtilTraceMessagesByCustomerId(customer.CustomerId).subscribe({
      next: (res) => {
        this.customerMessages = res;
      }
    })
    this.dlxClient.GetUserInfosByCustomerId(customer.CustomerId).subscribe({
      next: (res) => {
        this.customerUsers = res;
      
      }
    })

    this.dlxClient.GetCustomerInfoByCustomerId(customer.CustomerId).subscribe({
      next: (res) => {
      
        this.datalexServiceURL = res.DatalexServiceURL;
        //this.datalexWebAppURL = res.DatalexWebURL;
      }
    })
    this.dlxClient.GetCustomerByIdWithStat(customer.CustomerId).subscribe({
      next: (res) => {
        this.customerStatistic = res;
      }
    })
    this.dlxClient.GetUtilTraceMessagesBySourceId(customer.CustomerId).subscribe({
      next: (res) => {
        this.messageDetails = res;
      }
    })

    if (this.customer.CaseId !== null) {
      this.dlxClient.GetCase(this.customer.CaseId, false).subscribe({
        next: (res) => {
          this.customerCase = `${res.Number} - ${res.Title}`;
          this.canOpenCase = true;
        }
      })
    }
  }

  populateCustomer(customer: ICustomerBE) {
    if (customer.Name) this.name = customer.Name
    if (customer.EndDate) this.endDate = customer.EndDate;
    if (customer.MaxUsers) this.maxUsers = customer.MaxUsers;
    if (customer.WebAppURL) this.webAppURL = customer.WebAppURL;
    if (customer.ShortName) this.shortName = customer.ShortName;
  }

  handleSelection(e: IGridCellEventArgs) {
    this.selectedMessage = e.cell.row.data; //e.added[0]
    this.isHidden = false;
    e.cell.grid.clearCellSelection();
    // this.escListener 
  }


  openCase(id: string | null) {
  
    this.self.destroy();
    this.router.navigate(['/case', id]);
  }


  getData() {
    this.getCustomer(this.customer)
  }

  save() {
  
    // this.dlxClient.SaveCustomer(this.editedCustomer).subscribe({
    //   next: (res) => {
    //     this.customer = res;
    //     this.editedCustomer = JSON.parse(JSON.stringify(res));
    //     this.populateCustomer(res);
    //   }
    // })
  }


  caseLookup(event: MouseEvent | KeyboardEvent, input: HTMLInputElement) {
    event.stopPropagation();

    if (event.type === "keypress" && (event as KeyboardEvent).code === 'Enter') {
      this.onCaseLookUp(input)
    }
    else if (event.type === 'click') {
      this.onCaseLookUp(input)
    }
  }

  onCaseLookUp(input: HTMLInputElement) {

    if (!isNaN(Number(input.value))) {
      this.dlxClient.GetCaseByNumber(Number(input.value)).subscribe({
        next: (res) => {
          this.customerCase = `${res.Number} - ${res.CaseClient}`;
          this.editedCustomer.CaseId = res.Id;
          this.compare(this.customer, this.editedCustomer);
          this.canOpenCase = true;
        }
      })
    } else {
      this.onSearch(input.value, null)

    }
  }

  onSearch(input: string, _adv: IAdvSearch | null) {
    // this.emptyGridMessage = 'Søker...';

    const a = {
      caseNumber: null,
      caseTitle: _adv !== null ? this.emptyStringToNull(_adv.titleDesc) : null,
      caseHandlerIdOrCaseResponsibleId: _adv !== null ? this.emptyStringToNull(_adv.sasb) : null,
      dicipline: null,
      reference: null,
      roleTypeCategoryId: null,
      roleTypeId: null,
      nameInitials: _adv !== null ? this.emptyStringToNull(_adv.nameInitials) : this.emptyStringToNull(input),
      searchOrOnTitle: true,
      searchActiveCases: _adv !== null ? _adv.active : true,
      searchRestricted: _adv !== null ? _adv.restricted : true,
      searchClosed: _adv !== null ? _adv.closed : false,
      searchInternal: _adv !== null ? _adv.internal : false,
      fuzzySearchContact: false
    }

    this.dlxClient.FindCasesNew(
      a.caseNumber,
      a.caseTitle!,
      a.caseHandlerIdOrCaseResponsibleId,
      a.dicipline!,
      a.reference!,
      a.roleTypeCategoryId,
      a.roleTypeId,
      a.nameInitials!,
      a.searchOrOnTitle,
      a.searchActiveCases,
      a.searchRestricted,
      a.searchClosed,
      a.searchInternal,
      a.fuzzySearchContact).subscribe({
        next: (response) => {
          if (response.length === 1) {
            this.customerCase = `${response[0].CaseNumber} - ${response[0].CaseClient}`;
            this.editedCustomer.CaseId = response[0].CaseId;
            this.compare(this.customer, this.editedCustomer);
            this.canOpenCase = true;
          } else {
            this.searchedCases = response;
            this.adv.nameInitials = input
            this.isHidden = false;
          }
        }
      })
  }



  compare(origin: ICustomerBE, edited: ICustomerBE) {
    let _origin = JSON.stringify(origin);
    let _edited = JSON.stringify(edited);
    this.updated = (_origin === _edited);
  }

  checkType(e: any) {
    if (e === "") {
      this.editedCustomer.CaseId = null;
      this.compare(this.customer, this.editedCustomer)
      this.canOpenCase = false;
    }

  }

  emptyStringToNull(string: string | null) {
    if (string === "") return null
    return string
  }
  onCaseSelection(event: { added: ICaseSearchResultBE[] }) {
    const { CaseNumber, CaseClient, CaseId } = event.added[0];
    this.customerCase = `${CaseNumber} - ${CaseClient}`;
    this.editedCustomer.CaseId = CaseId;
    this.canOpenCase = true;
    // this.close();
  }

  close() {
    this.searchedCases = null;
    this.isHidden = false;
    this.compare(this.customer, this.editedCustomer);
  
  }

  search() {
    const a = {
      caseNumber: null,
      caseTitle: this.emptyStringToNull(this.adv.titleDesc),
      caseHandlerIdOrCaseResponsibleId: this.adv.sasb === '00000-0000-00000-00000' ? null : this.adv.sasb,
      dicipline: null,
      reference: null,
      roleTypeCategoryId: null,
      roleTypeId: null,
      nameInitials: this.emptyStringToNull(this.adv.nameInitials),
      searchOrOnTitle: true,
      searchActiveCases: this.adv.active,
      searchRestricted: this.adv.restricted,
      searchClosed: this.adv.closed,
      searchInternal: this.adv.internal,
      fuzzySearchContact: false
    }

    this.dlxClient.FindCasesNew(
      a.caseNumber,
      a.caseTitle!,
      a.caseHandlerIdOrCaseResponsibleId,
      a.dicipline!,
      a.reference!,
      a.roleTypeCategoryId,
      a.roleTypeId,
      a.nameInitials!,
      a.searchOrOnTitle,
      a.searchActiveCases,
      a.searchRestricted,
      a.searchClosed,
      a.searchInternal,
      a.fuzzySearchContact).subscribe({
        next: (response) => {
          this.searchedCases = response;
        }
      })
  }
  onReset() {
    this.adv = {
      nameInitials: null,
      titleDesc: null,
      sasb: '00000-0000-00000-00000',
      active: true,
      restricted: true,
      closed: false,
      internal: false
    }
  }

  messageHidden: boolean = true;

  openMessage(e: IUtilTraceMessagesBE) {
  
    this.selectedMessage = e;
  }

  popUpClose(key: string) {
    //@ts-ignore
    this[key] = null;
  }

  formatDate2(date: Date | string, includeMinutes: boolean = true) {

    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hh = '' + d.getHours(),
      mm = '' + d.getMinutes(),
      ss = '' + d.getSeconds();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (hh.length < 2)
      hh = '0' + hh;
    if (mm.length < 2)
      mm = '0' + mm;
    if (ss.length < 2)
      ss = '0' + ss;

    return includeMinutes ? `${day}.${month}.${year}  ${hh}:${mm}:${ss}` : `${year}-${month}-${day}`

  }

}


/*
  GetUtilTraceMessagesByCustomerId = Meldinger
  GetCustomerByIdWithStat = Statetikk/Info
  GetUserInfosByCustomerId = Brukere
"2024-01-01T01:00:00+01:00"
"2024-01-01T00:00:00+01:00"
 
 */