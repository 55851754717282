@if(dcs.getDocuments().length > 0){
<div class="button-wrapper">
  <button igxButton [igxToggleAction]="dropdown1" [overlaySettings]="overlaySettings"
    [style]="'background: #D3E3FD; color: black'">
    Utsjekkede dokumenter
  </button>
  <igx-badge [style]="'background: #ED1717 ; color: white'">{{dcs.getDocuments().length}}</igx-badge>
</div>

<igx-drop-down class="checked-docs-dropdown custom-dropdown-width" #dropdown1>
  <igx-list>

    <igx-list-item [isHeader]="true">
      <div class="list-header">
        <span> Tittel </span>
        <span> Dato </span>
      </div>
      <div class="check-button">
        <button igxButton igxRipple [style]="'background: #D7F5AF ; color: #185613'" (click)="removeAll()">
          Sjekk inn alle
        </button>
      </div>
    </igx-list-item>

    <igx-list-item igxRipple="pink" igxRippleTarget=".igx-list__item-content"
      *ngFor="let doc of dcs.getDocuments(); index as i">
      <div igxListLineTitle class="name">
        <div class="title"> {{doc.Title}} </div>
        <span dataType="date"> {{doc.CheckedOut | date :"dd.MM.YY" }}</span>
      </div>

      <div class="check-button">
        @if (dcs.spinners[i]) {
        <span class="spinner">
          <igx-circular-bar [animate]="false" [indeterminate]="true" [textVisibility]="false" />
        </span>
        } @else {
        <button class="button-sample" igxButton="outlined" igxRipple (click)="removeRow(doc, i);">{{doc.Read ? "Rydd" : "Sjekk inn"}}</button>
        }
      </div>
    </igx-list-item>

  </igx-list>
</igx-drop-down>
}