import { ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DocumentDetailEditorComponent } from 'src/app/components/pages/selected-case-page/document/document-detail-editor/document-detail-editor.component';
import { IDocumentBE } from '@datalex-software-as/datalex-client'

@Component({
    selector: 'app-document-details-modal',
    templateUrl: './document-details-modal.component.html',
    styleUrls: ['../modal.component.scss'],
    standalone: true
})
export class DocumentDetailsModalComponent implements OnInit {
  public document!: IDocumentBE;
  public self!: ComponentRef<DocumentDetailsModalComponent>;

  constructor(private factory: ComponentFactoryResolver, private change: ChangeDetectorRef) {
  }

  @ViewChild("modalRef", { read: ViewContainerRef }) $ref!: ViewContainerRef;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.$ref.clear();
    const component = this.$ref.createComponent(DocumentDetailEditorComponent);
    component.instance.source = this.document;
    document.addEventListener("keydown", this.onKeyboardPress)
    this.change.detectChanges();
  }

  ngOnDestroy(): void {
    document.removeEventListener("keydown", this.onKeyboardPress);
    this.$ref.clear();
  }

  destroyModal() {
    this.self.destroy();
  }

  onKeyboardPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.self.destroy();
    }
  }
}

/*
public setDocument(note: IDocumentLimitedBE) {
  this.dlxDocument.GetDocument(this.sys.token, note.Id, this.sys.webURL).subscribe((response) => {
    if(response.FileId) 
    {
      this.dlxDocument.GetFileWithoutFileData(this.sys.token, response.FileId, this.sys.webURL).subscribe((fileResponse) => {
        response.File = fileResponse;
        this.document = response;
      })
    } 
    else 
    {
      this.document = response;
    }

  })

}

*/