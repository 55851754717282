@if(showNavBar){
  <app-navigation-bar />
}
<activity-log-overlay></activity-log-overlay>
<modal-hub></modal-hub>
<div [ngClass]="{'content': !isPdfViewer}">
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
<app-information-overlay></app-information-overlay>

@if(showToolBar){
  <app-toolbar />

}